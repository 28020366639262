import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useQuery,
  useMutation,
  useGet,
  usePost,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import Drawer from "@vapor/react-material/Drawer";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Paper from "@vapor/react-material/Paper";
import Button from "@vapor/react-material/Button";
import Box from "@vapor/react-material/Box";
import Card from "@vapor/react-material/Card";
import CardContent from "@vapor/react-material/CardContent";
import Tooltip from "@vapor/react-material/Tooltip";
import Modal from "@vapor/react-material/Modal";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import TextField from "@vapor/react-material/TextField";
import Autocomplete from "@vapor/react-material/Autocomplete";
import Checkbox from "@vapor/react-material/Checkbox";
import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemText from "@vapor/react-material/ListItemText";
import ListItemButton from "@vapor/react-material/ListItemButton";
import ListItemIcon from "@vapor/react-material/ListItemIcon";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";
import ExtendedAccordion from "@vapor/react-extended/ExtendedAccordion";

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

import VaporUploader from "@vapor/react-custom/VaporUploader";
import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";

import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

//Icons
import {
  Home as HomeIcon,
  Print as PrintIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  AddShoppingCart as AddShoppingCartIcon,
  AttachFile as AttachFileIcon,
  InsertDriveFile as FileIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Sos as SosIcon,
  ArrowForward as DetailIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";
import { ModalDelete } from "../../components/ModalDelete";
import { AnagraficaForm } from "../../components/anagrafica/AnagraficaForm";
import { PrestazioneForm } from "../../components/prestazione/PrestazioneForm";
import { RischioForm } from "../../components/rischio/RischioForm";
import { IndicatoreAnomaliaForm } from "../../components/indicatore_anomalia/IndicatoreAnomaliaForm";

//Constants, Api and Data Models
import {
  bsaFascicolo,
  bsaSegnalazione,
  pathFascicoloAdd,
} from "../../businessLogic/constants";
import {
  getLocalStorageSelectedFascicolo,
  setLocalStorageSelectedFascicolo,
  removeLocalStorageSelectedEntity,
  getUniqueFileName,
  getUniqueReportFileName,
  getFascicoloStatus,
  isDateValid,
  getLocalStorageSelectedEntity,
} from "../../businessLogic/bl";

import {
  userPermissionModel,
  fascicoloModel,
  anagraficaModel,
  prestazioneModel,
  prestazionePagamentoModel,
  rischioModel,
  userModel,
  allegatoModel,
  baseTipoAnagraficaModel,
  baseTipoDocumentoModel,
  bancaDatiModel,
  anagraficaBancaDatiModel,
  sosModel,
  baseRischioGradoModel,
  baseTipoPartecipazioneModel,
  baseIndicatoreAnomaliaModel,
  indicatoreAnomaliaModel,
} from "../../businessLogic/models";

import {
  query_users_byCompanyId,
  query_fascicolo_byId,
  query_anagrafiche_byFascicolo,
  query_base_tipo_anagrafica,
  query_base_tipo_documento,
  query_allegato_byEntity,
  query_bancaDati_byIdent,
  query_bancaDati_byName,
  query_sos_byFascicolo,
  query_prestazioni_byFascicolo,
  query_rischi_byPrestazione,
  query_rischi_byTenant,
  query_base_rischio_grado_byTenantTipo,
  query_base_tipo_partecipazione,
  query_base_indicatore_anomalia,
  query_indicatori_anomalia_byFascicolo,
  query_base_comune_byId,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_add_allegato,
  mutation_delete_allegato,
  mutation_add_anagrafica_bancadati,
  mutation_add_sos,
  mutation_update_sos,
  mutation_delete_sos,
  mutation_update_fascicolo,
  mutation_get_document_and_save,
  mutation_delete_allegato_fromStorage,
  mutation_upload_allegato_toStorage,
} from "../../businessLogic/mutation";

//Style
import {
  modalStyleLarge,
  divGreen,
  divOrange,
} from "../../businessLogic/styles";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";

export const FascicoloAdd = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const selectedFascicolo = getLocalStorageSelectedFascicolo();
  const entityFromNavigation = getLocalStorageSelectedEntity();

  //Query
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiFascicoloById = useQuery(
    query_fascicolo_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBancaDatiByIdent = useQuery(
    query_bancaDati_byIdent,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBancaDatiByName = useQuery(
    query_bancaDati_byName,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoAnagrafica = useQuery(
    query_base_tipo_anagrafica,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoDocumento = useQuery(
    query_base_tipo_documento,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaByFascicolo = useQuery(
    query_anagrafiche_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAllegatiByEntity = useQuery(
    query_allegato_byEntity,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiSOSByFascicolo = useQuery(
    query_sos_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestazioniByFascicolo = useQuery(
    query_prestazioni_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischiByPrestazione = useQuery(
    query_rischi_byPrestazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischiByTenant = useQuery(
    query_rischi_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseRischioGradoByTenantTipo = useQuery(
    query_base_rischio_grado_byTenantTipo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoPart = useQuery(
    query_base_tipo_partecipazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseIndAnomalia = useQuery(
    query_base_indicatore_anomalia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiIndicatoriByFascicolo = useQuery(
    query_indicatori_anomalia_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiComuneById = useQuery(
    query_base_comune_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAllegato = useMutation(mutation_add_allegato, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteAllegato = useMutation(mutation_delete_allegato, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagraficaBancaDati = useMutation(
    mutation_add_anagrafica_bancadati,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddSos = useMutation(mutation_add_sos, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateSos = useMutation(mutation_update_sos, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteSos = useMutation(mutation_delete_sos, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateFascicolo = useMutation(mutation_update_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiGetJsReportDocumentAndSave = useMutation(
    mutation_get_document_and_save,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUploadAllegatoToStorage = useMutation(
    mutation_upload_allegato_toStorage,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteAllegatoFromStorage = useMutation(
    mutation_delete_allegato_fromStorage,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [backDropInternalOpen, setBackDropInternalOpen] = useState(false); //Loader utilizzato durante il caricamento

  //const [isTenantEnabled, setIsTenantEnabled] = useState(false);
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaFascicolo,
      false,
      false,
      false,
      false
    )
  );
  const [userPermissionsSOS, setUserPermissionsSOS] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaSegnalazione,
      false,
      false,
      false,
      false
    )
  );

  //Fascicolo
  const [selFascicolo, setSelFascicolo] = useState(
    new fascicoloModel(
      0,
      tenantId,
      aziendaId,
      "",
      new Date(),
      false,
      new Date(),
      new Date(),
      [],
      "",
      "",
      loggedUserId
    )
  );
  const [selFascicoloOriginal, setSelFascicoloOriginal] = useState(
    new fascicoloModel(
      0,
      tenantId,
      aziendaId,
      "",
      new Date(),
      false,
      new Date(),
      new Date(),
      [],
      "",
      "",
      loggedUserId
    )
  ); //Used for Audit
  const [chbVal, setChbVal] = useState({ isPrivate: false });

  //Anagrafica
  //const [selAnagrafica, setSelAnagrafica] = useState(new anagraficaModel(0));

  //Prestazione
  const [selPrestazione, setSelPrestazione] = useState(new prestazioneModel(0));

  //Professionisti
  const [utentiList, setUtentiList] = useState([]);
  const [filteredUtentiList, setFilteredUtentiList] = useState([]);

  //Tabelle di base
  const [baseTipoAnagList, setBaseTipoAnagList] = useState([]); //base_tipo_anagrafica
  const [baseTipoDocList, setBaseTipoDocList] = useState([]); //base_tipo_documento
  const [baseRischioGradoList, setBaseRischioGradoList] = useState([]); //Contiene i gradi di rischio per il tipo di tenant
  const [baseTipoPartList, setBaseTipoPartList] = useState([]); //base_tipo_partecipazione
  const [baseIndAnomaliaList, setBaseIndAnomaliaList] = useState([]); //base_indicatore_anomalia

  //Allegati
  const [currentAllegati, setCurrentAllegati] = useState([]);

  //Banca dati
  const [bancaDatiTabValue, setBancaDatiTabValue] = useState("tabBdGratuite");
  const handleChangeBancaDatiTab = (event, newValue) => {
    setBancaDatiTabValue(newValue);
  };
  const [hasBancaDatiAbb, setHasBancaDatiAbb] = useState(false);
  const [bancaDatiAbbCredits, setBancaDatiCredits] = useState(0);
  const [bancaDatiAbbProductsList, setBancaDatiAbbProductsList] = useState([]);
  const [bancaDatiAbbProductBought, setBancaDatiAbbProductBought] =
    useState(false);

  const [bancaDatiAbbResultListNeg, setBancaDatiAbbResultListNeg] = useState({
    BLP: [],
    DEB: [],
    DIB: [],
  });
  const [bancaDatiAbbResultListPar, setBancaDatiAbbResultListPar] = useState({
    PAR1: [],
    PAR2: [],
  });
  const [bancaDatiAbbResultListStr, setBancaDatiAbbResultListStr] = useState({
    CAR: [],
    SOC: [],
    SIN: [],
  });

  const [bancaDatiResult, setBancaDatiResult] = useState({
    Pep_Regionali: false,
    Pep_Metropolitani: false,
    Pep_Provinciali: false,
    Pep_Comunali: false,
    Pep_MaggiorOrgano: false,
    Pep_OrganiStraordinari: false,
    Pep_SindaciInCarica: false,
    Pep_Comune: false,
    UNSC: false,
    OFAC: false,
    UE: false,
    Negativita: false,
    Partecipazioni: false,
    StrutturaSocietaria: false,
  });
  const [anagraficheBancaDatiList, setAnagraficheBancaDatiList] = useState([]); //Contiene i risultati delle banche dati di tutte le anagrafiche
  const [currentAnagraficheList, setCurrentAnagraficheList] = useState([]); //Contiene le anagrafiche con l'ultima versione
  const [currentAnagrafica, setCurrentAnagrafica] = useState(
    new anagraficaModel(
      0,
      0,
      false,
      "",
      "",
      "",
      0,
      "",
      null,
      "",
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      undefined,
      undefined,
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      false,
      1,
      new Date(),
      false,
      [],
      [],
      "",
      loggedUserId
    )
  ); //Contiene l'anagrafica corrente
  const [currentSOSList, setCurrentSOSList] = useState([]);
  const [currentSOS, setCurrentSOS] = useState(
    new sosModel(
      0,
      new Date(),
      "",
      "",
      "",
      loggedUserId,
      new Date(),
      undefined,
      loggedUserId
    )
  ); //Contiene la SOS corrente
  const [currentOriginalSOS, setCurrentOriginalSOS] = useState(
    new sosModel(
      0,
      new Date(),
      "",
      "",
      "",
      loggedUserId,
      new Date(),
      undefined,
      loggedUserId
    )
  ); //Contiene la SOS corrente
  const [filteredSOSList, setFilteredSOSList] = useState([]);

  //Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da visualizzare
  const [typeToPerform, setTypeToPerform] = useState("ALL"); //Contiene il tipo di record da eliminare. ALL: Allegato

  //TS Digital
  const [accessToken, setAccessToken] = useState(useAuth().token);

  const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });
  const tsApiModeFinance = useGet(
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    },
    { lazy: true }
  );
  const tsApiModeFinanceBuyProduct = usePost(
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    },
    { lazy: true }
  );
  const tsApiModeFinanceGetDocument = useGet(
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    },
    { lazy: true }
  );

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          /**************************************/
          //Carico i permessi relativi all'utente
          setUserPermissions(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaFascicolo)[0]
          );
          setUserPermissionsSOS(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaSegnalazione)[0]
          );

          /**************************************/
          //Carico gli utenti
          const usersResponse = await apiUsersByCompanyId.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const arrUsers = [];
          usersResponse.data.data.user_tenant.map((item) =>
            arrUsers.push(
              new userModel(
                item.UserId,
                item.TenantId,
                item.user.Username,
                item.user.Email,
                item.user.Name,
                item.user.Surname,
                item.user.Name + " " + item.user.Surname,
                item.Type,
                item.user.Locale,
                item.TenantTipoId,
                item.user.IsRespAntiRic,
                item.base_tipo_tenant.Value,
                item.user.IsActive,
                item.user.IsDeleted,
                item.user.IsBanned,
                item.user.CreatedOn,
                item.user.Cf
              )
            )
          );
          setUtentiList(arrUsers);

          /**************************************/
          //Carico le tabelle di base
          /*base_tipo_anagrafica*/
          const tbTipoAnagResponse = await apiBaseTipoAnagrafica.fetch({
            tenantId: tenantId,
          });
          const arrTipoAnagItems = [];
          tbTipoAnagResponse.data.data.base_tipo_anagrafica.map((item) =>
            arrTipoAnagItems.push(
              new baseTipoAnagraficaModel(
                item.Id,
                item.Key,
                item.Value,
                item.Order,
                item.IsSystem
              )
            )
          );
          setBaseTipoAnagList(arrTipoAnagItems);

          /*base_tipo_documento*/
          const tbTipoDocResponse = await apiBaseTipoDocumento.fetch({
            tenantId: tenantId,
          });
          const arrTipoDocItems = [];
          tbTipoDocResponse.data.data.base_tipo_documento.map((item) =>
            arrTipoDocItems.push(
              new baseTipoDocumentoModel(
                item.Id,
                item.Key,
                item.Value,
                item.Order,
                item.IsSystem,
                item.PersonType
              )
            )
          );
          setBaseTipoDocList(arrTipoDocItems);

          /*base_rischio_grado*/
          const tipoTenantId =
            sharedDatas.getPropertyByName("userModel").tenantTipoId;
          const baseRischioGradoResponse =
            await apiBaseRischioGradoByTenantTipo.fetch({
              tenantTipoId: tipoTenantId,
            });
          let arrBaseRischioGrado = [];
          baseRischioGradoResponse.data.data.base_rischio_grado.map((item) =>
            arrBaseRischioGrado.push(
              new baseRischioGradoModel(
                item.Id,
                item.TipoRischio,
                item.GradoRischio,
                item.AdeguataVerifica,
                item.ValoreRischioDa,
                item.ValoreRischioA,
                item.Colore,
                item.Offset
              )
            )
          );
          setBaseRischioGradoList(arrBaseRischioGrado);

          /*base_tipo_partecipazione*/
          const baseTipoPartResponse = await apiBaseTipoPart.fetch();
          const arrTipoPartItems = [];
          baseTipoPartResponse.data.data.base_tipo_partecipazione.map((item) =>
            arrTipoPartItems.push(
              new baseTipoPartecipazioneModel(
                item.Id,
                item.Key,
                item.Nome,
                item.Descrizione
              )
            )
          );
          setBaseTipoPartList(arrTipoPartItems);

          /*base_indicatore_anomalia*/
          const tbBaseIndAnomaliaResponse = await apiBaseIndAnomalia.fetch();
          const arrIndAnomaliaItems = [];
          tbBaseIndAnomaliaResponse.data.data.base_indicatore_anomalia.map(
            (item) =>
              arrIndAnomaliaItems.push(
                new baseIndicatoreAnomaliaModel(
                  item.Id,
                  item.ParentId,
                  item.Section,
                  item.Point,
                  item.SubPoint,
                  item.Description,
                  item.IsVisible
                )
              )
          );
          setBaseIndAnomaliaList(arrIndAnomaliaItems);

          /**************************************/
          //Carico il fascicolo
          const selectedFascicoloResponse = await selectedFascicolo;
          const itemId = parseInt(selectedFascicoloResponse);
          let fascicolo = new fascicoloModel();

          //Se non è valorizzato ne sto creando uno nuovo
          // if (itemId === 0) {
          //   fascicolo = new fascicoloModel(0, tenantId, "", new Date(), false, null, null, []);
          //   setSelFascicolo(fascicolo);
          // }

          //altrimenti vuol dire che ne voglio visualizzare il dettaglio
          if (itemId > 0) {
            const fascicoloResponse = await apiFascicoloById.fetch({
              id: itemId,
            });
            const item = fascicoloResponse.data.data.fascicolo_by_pk;
            fascicolo = new fascicoloModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.Name,
              item.Date,
              item.IsPrivate,
              item.CreatedOn,
              item.ModifiedOn,
              [],
              "",
              "",
              item.CreatedBy,
              item.ModifiedBy
            );

            let professionisti = [];

            // Aggiungo i professionisti che hanno effettuato un'identificazione
            for (let i = 0; i < item.anagrafica_fascicolos.length; i++) {
              const identList = item.anagrafica_fascicolos[i].anagrafica.anagrafica_identificaziones;

              for (let m = 0; m < identList.length; m++) {
                const ident = identList[m];

                if (professionisti.filter(x => x.userId === ident.IdentifiedByUser).length <= 0) {
                  professionisti.push(arrUsers.filter(x => x.userId === ident.IdentifiedByUser)[0]);
                }
              }
            }

            // Aggiungo i professionisti che hanno effettuato una prestazione
            for (let i = 0; i < item.prestaziones.length; i++) {
              const prestUsersList = item.prestaziones[i].prestazione_users;

              for (let m = 0; m < prestUsersList.length; m++) {
                const prestUser = prestUsersList[m];

                if (professionisti.filter(x => x.userId === prestUser.UserId).length <= 0) {
                  professionisti.push(arrUsers.filter(x => x.userId === prestUser.UserId)[0]);
                }
              }
            }

            setFilteredUtentiList(professionisti);

            setSelFascicolo(fascicolo);
            setSelFascicoloOriginal(fascicolo);
            setChbVal({ isPrivate: fascicolo.isPrivate });

            const status = await getFascicoloStatus(item);
            setStatusFascicolo(status.status);

            let statusReasons = "";
            if (!status.status) {
              for (let i = 0; i < status.reasons.length; i++) {
                statusReasons = statusReasons + status.reasons[i] + "\n";
              }
              setStatusFascicoloReasons(statusReasons);
            }

            //Last Activity
            await apiDeleteLastActivity.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              userId: loggedUserId,
            });
            await apiAddLastActivity.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              path: pathFascicoloAdd,
              entityId: fascicolo.id.toString(),
              entityName: fascicolo.name,
            });
          }

          //Carico gli allegati
          apiAllegatiByEntity
            .fetch({
              tenantId: tenantId,
              entityType: "fascicolo",
              entityId: itemId,
            })
            .then((response) => {
              var allegati = [];
              response.data.data.allegato.forEach((allegato) => {
                allegati.push(
                  new allegatoModel(
                    allegato.Id,
                    tenantId,
                    allegato.EntityType,
                    allegato.EntityId,
                    allegato.FileName,
                    allegato.FileType,
                    allegato.FileUrl,
                    allegato.DisplayedName,
                    allegato.CreatedOn,
                    allegato.ModifiedOn,
                    allegato.CreatedBy,
                    allegato.ModifiedBy
                  )
                );
              });
              setCurrentAllegati(allegati);
            })
            .catch((e) => {
              apiAddLog.fetch({
                tenantId: sharedDatas.getPropertyByName("tenantId"),
                aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "FascicoloAdd",
                method: "loadDatas/apiAllegatiByEntity.fetch",
                version: sharedDatas.getPropertyByName("version"),
              });
            });

          //Carico le SOS
          var sos = [];
          apiSOSByFascicolo
            .fetch({
              fascicoloId: itemId,
            })
            .then((response) => {
              response.data.data.sos.forEach((item) => {
                sos.push(
                  new sosModel(
                    item.Id,
                    item.Date,
                    item.Motivo,
                    item.Note,
                    item.ReceiptId,
                    item.ReportedByUser,
                    item.CreatedOn,
                    item.ModifiedOn,
                    item.CreatedBy,
                    item.ModifiedBy
                  )
                );
              });
              setCurrentSOSList(sos);
            })
            .catch((e) => {
              apiAddLog.fetch({
                tenantId: sharedDatas.getPropertyByName("tenantId"),
                aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "FascicoloAdd",
                method: "loadDatas/apiSOSByFascicolo.fetch",
                version: sharedDatas.getPropertyByName("version"),
              });
            });

          //Carico l'abbonamento da ModeFinance
          try {
            await tsApiModeFinance
              .fetch({
                url:
                  "aml:mode-finance:api://companies/fiscal_code/" +
                  sharedDatas.getPropertyByName("tenantModel").identifier +
                  "/products?item_id=" +
                  tenantId,
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "X-App-Name": "TS420",
                  "X-App-Version": "1.0.0",
                  "X-Request-ID": uuidv4(),
                  "X-Correlation-ID": uuidv4(),
                  "Content-Type": "application/json",
                  "X-Item-ID": tenantId,
                  "X-User-ID": loggedUserId,
                },
              })
              .then((response) => {
                setHasBancaDatiAbb(true);
                setBancaDatiCredits(response.data.credits);
                setBancaDatiAbbProductsList(
                  response.data.products.filter(
                    (x) =>
                      x.product === "NEGATIVITA" ||
                      x.product === "PARTECIPAZIONI" ||
                      x.product === "STRUTTURA_SOCIETARIA"
                  )
                );
              })
              .catch((e) => {
                if (
                  !e.stack.includes(
                    "AxiosError: Request failed with status code 403"
                  )
                ) {
                  apiAddLog.fetch({
                    tenantId: sharedDatas.getPropertyByName("tenantId"),
                    aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                    level: "ERROR",
                    message: e.message,
                    stack: e.stack,
                    area: "FascicoloAdd",
                    method: "loadDatas/tsApiModeFinance.fetch",
                    version: sharedDatas.getPropertyByName("version"),
                  });
                }
              });
          } catch (e) {
            apiAddLog.fetch({
              tenantId: sharedDatas.getPropertyByName("tenantId"),
              aziendaId: sharedDatas.getPropertyByName("aziendaId"),
              level: "ERROR",
              message: e.message,
              stack: e.stack,
              area: "FascicoloAdd",
              method: "loadDatas",
              version: sharedDatas.getPropertyByName("version"),
            });
          }

          const entityFromNavigationResult = await entityFromNavigation;
          let entityFromNavigationResultJson = null;
          if (entityFromNavigationResult !== null) {
            entityFromNavigationResultJson = JSON.parse(
              entityFromNavigationResult
            );

            switch (entityFromNavigationResultJson.entityType) {
              case "anagrafica": {
                setSelectedTab("tabAnagrafica");
                break;
              }
              case "prestazione": {
                setSelectedTab("tabPrestazione");
                break;
              }
              case "rischio": {
                setSelectedTab("tabRischio");
                break;
              }
              case "sos": {
                setSelectedTab("tabAnagrafica");
                setOpenDrawer(true);
                setDrawerTabValue("sos");
                setDrawerHeaderTitle("SOS");
                setDrawerHeaderDesc("Segnalazioni di Operazioni Sospette");

                const selectedSos = sos.filter(
                  (x) => x.id === entityFromNavigationResultJson.entityId
                )[0];
                setCurrentSOS(selectedSos);
                setCurrentOriginalSOS(selectedSos);
                break;
              }
              default: {
                // setSelectedTab("tabAnagrafica");
                break;
              }
            }

            await removeLocalStorageSelectedEntity();
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Home Button
  const handleGoHome = () => {
    setLocalStorageSelectedFascicolo(0);
    navigate(basePath);
  };

  //Tabs
  const [selectedTab, setSelectedTab] = useState("tabAnagrafica");
  const handleChangeTab = (event, newValue) => {
    setSelPrestazione(new prestazioneModel(0));
    setSelectedTab(newValue);
  };

  //Drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTabValue, setDrawerTabValue] = useState("bancaDati");
  const [drawerHeaderTitle, setDrawerHeaderTitle] = useState("Banca dati");
  const [drawerHeaderDesc, setDrawerHeaderDesc] = useState(
    "Verifica anagrafica in Banche dati"
  );
  const handleDrawer = () => {
    //Se sto chiudendo il drawer resetto i valori
    if (openDrawer) {
      setSelBancaDatiItem(new bancaDatiModel(0, "", "", "", "", "", null, ""));
      setBancaDatiResult((prevState) => ({
        ...prevState,
        Pep_Regionali: false,
        Pep_Metropolitani: false,
        Pep_Provinciali: false,
        Pep_Comunali: false,
        Pep_MaggiorOrgano: false,
        Pep_OrganiStraordinari: false,
        Pep_SindaciInCarica: false,
        Pep_Comune: false,
        UNSC: false,
        OFAC: false,
        UE: false,
        Pep_Provincia: false,
      }));
      setSelBancaDatiItemValues([]);
      setSelBancaDatiItemKeys(null);
      setBancaDatiAbbResultListNeg((prevState) => ({
        ...prevState,
        BLP: [],
        DEB: [],
        DIB: [],
      }));
      setBancaDatiAbbResultListPar((prevState) => ({
        ...prevState,
        PAR1: [],
        PAR2: [],
      }));
      setBancaDatiAbbResultListStr((prevState) => ({
        ...prevState,
        CAR: [],
        SOC: [],
        SIN: [],
      }));
      setShowModalDetail(false);
      setBancaDatiTabValue("tabBdGratuite");
    }

    setOpenDrawer(!openDrawer);
  };
  const handleDrawerTabChange = (event, newValue) => {
    setDrawerTabValue(newValue);

    if (newValue === "bancaDati") {
      setDrawerHeaderTitle("Banca dati");
      setDrawerHeaderDesc("Verifica anagrafica in Banche dati");
    } else if (newValue === "documenti") {
      setDrawerHeaderTitle("Stampa");
      setDrawerHeaderDesc("Stampa documenti");
    } else if (newValue === "allegati") {
      setDrawerHeaderTitle("Allegati");
      setDrawerHeaderDesc("Allegati");
    } else if (newValue === "sos") {
      setDrawerHeaderTitle("SOS");
      setDrawerHeaderDesc("Segnalazioni di Operazioni Sospette");
    }
  };
  const openDrawerAction = (newValue) => {
    setDrawerTabValue(newValue);
    setOpenDrawer(true);

    if (newValue === "bancaDati") {
      setDrawerHeaderTitle("Banca dati");
      setDrawerHeaderDesc("Verifica anagrafica in Banche dati");
    } else if (newValue === "documenti") {
      setDrawerHeaderTitle("Stampa");
      setDrawerHeaderDesc("Stampa documenti");
    } else if (newValue === "allegati") {
      setDrawerHeaderTitle("Allegati");
      setDrawerHeaderDesc("Allegati");
    } else if (newValue === "sos") {
      setDrawerHeaderTitle("SOS");
      setDrawerHeaderDesc("Segnalazioni di Operazioni Sospette");
    }
  };

  //Status fascicolo
  const [statusFascicolo, setStatusFascicolo] = useState(false);
  const [statusFascicoloReasons, setStatusFascicoloReasons] = useState("");

  //Banca dati
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [selBancaDatiItem, setSelBancaDatiItem] = useState(
    new bancaDatiModel(0, "", "", "", "", "", null, "")
  );
  const [selBancaDatiItemKeys, setSelBancaDatiItemKeys] = useState([]);
  const [selBancaDatiItemValues, setSelBancaDatiItemValues] = useState(null);
  const [bancaDatiResultItems, setBancaDatiResultItems] = useState([]);

  //Banche dati pubbliche
  const handleCheckBancaDatiClick = async () => {
    try {
      setBackDropInternalOpen(true);

      if (
        currentAnagrafica.isPg &&
        currentAnagrafica.cf !== undefined &&
        currentAnagrafica.cf !== "" &&
        currentAnagrafica.denominazione !== undefined &&
        currentAnagrafica.denominazione !== ""
      ) {
        const bancaDatiResponse = await apiBancaDatiByIdent.fetch({
          identifier: "%" + currentAnagrafica.cf + "%",
          companyName: "%" + currentAnagrafica.denominazione + "%",
        });

        if (bancaDatiResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          const bancaDatiResultArr = Object.assign([], bancaDatiResultItems);

          const arrItems = [];
          if (bancaDatiResponse.data.data.bancadati.length > 0) {
            const anagBancaDatiResultArr = Object.assign(
              [],
              anagraficheBancaDatiList
            );

            for (
              var i = 0;
              i < bancaDatiResponse.data.data.bancadati.length;
              i++
            ) {
              const item = bancaDatiResponse.data.data.bancadati[i];
              bancaDatiResultArr.push(
                new bancaDatiModel(
                  0,
                  item.Repository,
                  "",
                  "",
                  "",
                  "",
                  null,
                  item.Infos
                )
              );
              arrItems.push(
                new bancaDatiModel(
                  0,
                  item.Repository,
                  "",
                  "",
                  "",
                  "",
                  null,
                  item.Infos
                )
              );

              if (
                item.Repository ===
                "United Nations Security Council Consolidated List"
              ) {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  UNSC: true,
                }));
              } else if (item.Repository === "OFAC Sanctions List") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  OFAC: true,
                }));
              } else if (item.Repository === "UE Sanctions List") {
                setBancaDatiResult((prevState) => ({ ...prevState, UE: true }));
              }

              //Salvo il risultato nella banca dati dell'anagrafica
              if (currentAnagrafica.id > 0) {
                await apiAddAnagraficaBancaDati.fetch({
                  anagraficaId: currentAnagrafica.id,
                  repository: item.Repository,
                  infos: JSON.stringify(item.Infos),
                });
                anagBancaDatiResultArr.push(
                  new anagraficaBancaDatiModel(
                    0,
                    currentAnagrafica.id,
                    item.Repository,
                    JSON.stringify(item.Infos),
                    new Date(),
                    loggedUserId
                  )
                );
              }
            }

            setBancaDatiResultItems(bancaDatiResultArr);
            setAnagraficheBancaDatiList(anagBancaDatiResultArr);

            //Invio una notifica tramite pub/sub interno
            postal.publish({
              channel: "bancaDati",
              topic: "bancaDati.result",
              data: {
                result: arrItems,
              },
            });
          } else {
            //Aggiungo comunque il risultato vuoto all'elenco, così da dimostrare di aver proceduto al controllo in banca dati
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "United Nations Security Council Consolidated List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "OFAC Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "UE Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "United Nations Security Council Consolidated List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "OFAC Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "UE Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );

            setBancaDatiResult((prevState) => ({
              ...prevState,
              UNSC: true,
              OFAC: true,
              UE: true,
            }));

            //Salvo il risultato nella banca dati dell'anagrafica
            if (currentAnagrafica.id > 0) {
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "United Nations Security Council Consolidated List",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "OFAC Sanctions List",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "UE Sanctions List",
                infos: "",
              });
            }

            setBancaDatiResultItems(bancaDatiResultArr);

            const anagBancaDatiResultArr = Object.assign(
              [],
              anagraficheBancaDatiList
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "United Nations Security Council Consolidated List",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "OFAC Sanctions List",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "UE Sanctions List",
                "",
                new Date(),
                loggedUserId
              )
            );
            setAnagraficheBancaDatiList(anagBancaDatiResultArr);

            //Invio una notifica tramite pub/sub interno
            postal.publish({
              channel: "bancaDati",
              topic: "bancaDati.result",
              data: {
                result: arrItems,
              },
            });

            enqueueSnackbar(t("message.noMatch"), { variant: "success" });
          }

          //Aggiorno la data di ultima modifica del fascicolo
          const updateFascicoloRespone = await apiUpdateFascicolo.fetch({
            id: selFascicolo.id,
            name: selFascicolo.name,
            date: selFascicolo.date,
            isPrivate: selFascicolo.isPrivate,
          });

          setBancaDatiTabValue("tabBdResoconto");
        }
      } else if (
        !currentAnagrafica.isPg &&
        currentAnagrafica.cf !== undefined &&
        currentAnagrafica.cf !== "" &&
        currentAnagrafica.nome !== undefined &&
        currentAnagrafica.nome !== "" &&
        currentAnagrafica.cognome !== undefined &&
        currentAnagrafica.cognome !== "" &&
        currentAnagrafica.nascitaData !== undefined &&
        currentAnagrafica.nascitaData !== ""
      ) {
        const bancaDatiResponse = await apiBancaDatiByName.fetch({
          name: "%" + currentAnagrafica.nome + "%",
          surname: "%" + currentAnagrafica.cognome + "%",
          bornDate: new Date(currentAnagrafica.nascitaData).toDateString(),
        });

        if (bancaDatiResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          const bancaDatiResultArr = Object.assign([], bancaDatiResultItems);

          const arrItems = [];
          if (bancaDatiResponse.data.data.bancadati.length > 0) {
            const anagBancaDatiResultArr = Object.assign(
              [],
              anagraficheBancaDatiList
            );

            for (
              var i = 0;
              i < bancaDatiResponse.data.data.bancadati.length;
              i++
            ) {
              const item = bancaDatiResponse.data.data.bancadati[i];
              bancaDatiResultArr.push(
                new bancaDatiModel(
                  0,
                  item.Repository,
                  "",
                  "",
                  "",
                  "",
                  null,
                  item.Infos
                )
              );
              arrItems.push(
                new bancaDatiModel(
                  0,
                  item.Repository,
                  "",
                  "",
                  "",
                  "",
                  null,
                  item.Infos
                )
              );

              if (item.Repository === "PEP - Amministratori Regionali") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_Regionali: true,
                }));
              } else if (
                item.Repository === "PEP - Amministratori Metropolitani"
              ) {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_Metropolitani: true,
                }));
              } else if (
                item.Repository === "PEP - Amministratori Provinciali"
              ) {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_Provinciali: true,
                }));
              } else if (item.Repository === "PEP - Amministratori Comunali") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_Comunali: true,
                }));
              } else if (item.Repository === "PEP - Maggior Organo") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_MaggiorOrgano: true,
                }));
              } else if (
                item.Repository === "PEP - Organi Straordinari in carica"
              ) {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_OrganiStraordinari: true,
                }));
              } else if (item.Repository === "PEP - Sindaci in carica") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_SindaciInCarica: true,
                }));
              } else if (item.Repository === "PEP - Comune") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_Comune: true,
                }));
              } else if (item.Repository.includes("PEP - Provincia (")) {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  Pep_Provincia: true,
                }));
              } else if (
                item.Repository ===
                "United Nations Security Council Consolidated List"
              ) {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  UNSC: true,
                }));
              } else if (item.Repository === "OFAC Sanctions List") {
                setBancaDatiResult((prevState) => ({
                  ...prevState,
                  OFAC: true,
                }));
              } else if (item.Repository === "UE Sanctions List") {
                setBancaDatiResult((prevState) => ({ ...prevState, UE: true }));
              }

              //Salvo il risultato nella banca dati dell'anagrafica
              if (currentAnagrafica.id > 0) {
                const bancaDatiAnagResponse =
                  await apiAddAnagraficaBancaDati.fetch({
                    anagraficaId: currentAnagrafica.id,
                    repository: item.Repository,
                    infos: JSON.stringify(item.Infos),
                  });

                if (bancaDatiResponse.data.errors !== undefined) {
                } else {
                  const result =
                    bancaDatiAnagResponse.data.data
                      .insert_anagrafica_bancadati_one;

                  anagBancaDatiResultArr.push(
                    new anagraficaBancaDatiModel(
                      result.Id,
                      currentAnagrafica.id,
                      result.Repository,
                      result.Infos,
                      result.CreatedOn,
                      result.CreatedBy
                    )
                  );
                }
              }
            }

            // const isPep = bancaDatiResultArr.filter(e => e.repository.includes("PEP")).length > 0;
            // setAnagPep((prevState) => ({ ...prevState, IsNotPep: !isPep, IsPep: isPep, IsNotPepMoreThanYear: false }));
            setBancaDatiResultItems(bancaDatiResultArr);

            setAnagraficheBancaDatiList(anagBancaDatiResultArr);

            //Invio una notifica tramite pub/sub interno
            postal.publish({
              channel: "bancaDati",
              topic: "bancaDati.result",
              data: {
                result: arrItems,
              },
            });
          } else {
            //Aggiungo comunque il risultato vuoto all'elenco, così da dimostrare di aver proceduto al controllo in banca dati
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Regionali",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Metropolitani",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Provinciali",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Comunali",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Maggior Organo",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Organi Straordinari in carica",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "PEP - Sindaci in carica",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(0, "PEP - Comune", "", "", "", "", null, "")
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "United Nations Security Council Consolidated List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "OFAC Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            bancaDatiResultArr.push(
              new bancaDatiModel(
                0,
                "UE Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );

            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Regionali",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Metropolitani",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Provinciali",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Amministratori Comunali",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Maggior Organo",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Organi Straordinari in carica",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "PEP - Sindaci in carica",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(0, "PEP - Comune", "", "", "", "", null, "")
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "United Nations Security Council Consolidated List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "OFAC Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );
            arrItems.push(
              new bancaDatiModel(
                0,
                "UE Sanctions List",
                "",
                "",
                "",
                "",
                null,
                ""
              )
            );

            setBancaDatiResult((prevState) => ({
              ...prevState,
              Pep_Regionali: true,
              Pep_Metropolitani: true,
              Pep_Provinciali: true,
              Pep_Comunali: true,
              Pep_MaggiorOrgano: true,
              Pep_OrganiStraordinari: true,
              Pep_SindaciInCarica: true,
              Pep_Comune: true,
              UNSC: true,
              OFAC: true,
              UE: true,
            }));

            //Salvo il risultato nella banca dati dell'anagrafica
            if (currentAnagrafica.id > 0) {
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Amministratori Regionali",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Amministratori Metropolitani",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Amministratori Provinciali",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Amministratori Comunali",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Maggior Organo",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Organi Straordinari in carica",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Sindaci in carica",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "PEP - Comune",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "United Nations Security Council Consolidated List",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "OFAC Sanctions List",
                infos: "",
              });
              await apiAddAnagraficaBancaDati.fetch({
                anagraficaId: currentAnagrafica.id,
                repository: "UE Sanctions List",
                infos: "",
              });
            }

            setBancaDatiResultItems(bancaDatiResultArr);

            const anagBancaDatiResultArr = Object.assign(
              [],
              anagraficheBancaDatiList
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Amministratori Regionali",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Amministratori Metropolitani",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Amministratori Provinciali",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Amministratori Comunali",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Maggior Organo",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Organi Straordinari in carica",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Sindaci in carica",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "PEP - Comune",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "United Nations Security Council Consolidated List",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "OFAC Sanctions List",
                "",
                new Date(),
                loggedUserId
              )
            );
            anagBancaDatiResultArr.push(
              new anagraficaBancaDatiModel(
                0,
                currentAnagrafica.id,
                "UE Sanctions List",
                "",
                new Date(),
                loggedUserId
              )
            );
            setAnagraficheBancaDatiList(anagBancaDatiResultArr);

            //Invio una notifica tramite pub/sub interno
            postal.publish({
              channel: "bancaDati",
              topic: "bancaDati.result",
              data: {
                result: arrItems,
              },
            });

            enqueueSnackbar(t("message.noMatch"), { variant: "success" });
          }

          //Aggiorno la data di ultima modifica del fascicolo
          const updateFascicoloRespone = await apiUpdateFascicolo.fetch({
            id: selFascicolo.id,
            name: selFascicolo.name,
            date: selFascicolo.date,
            isPrivate: selFascicolo.isPrivate,
          });

          setBancaDatiTabValue("tabBdResoconto");
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handleCheckBancaDatiClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };

  //Banche dati Mode Finance
  const handleBancaDatiBuyProduct = async (item) => {
    try {
      setBackDropInternalOpen(true);

      //Acquisto il prodotto
      let modeFinResponse = null;
      try {
        modeFinResponse = await tsApiModeFinanceBuyProduct.fetch({
          url:
            "aml:mode-finance:api://companies/fiscal_code/" +
            currentAnagrafica.cf +
            "/products/" +
            item.product +
            "?item_id=" +
            tenantId,
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-App-Name": "TS420",
            "X-App-Version": "1.0.0",
            "X-Request-ID": uuidv4(),
            "X-Correlation-ID": uuidv4(),
            "Content-Type": "application/json",
            "X-Item-ID": tenantId,
            "X-User-ID": loggedUserId,
          },
        });
      } catch (e) {
        apiAddLog.fetch({
          tenantId: sharedDatas.getPropertyByName("tenantId"),
          aziendaId: sharedDatas.getPropertyByName("aziendaId"),
          level: "ERROR",
          message: e.message,
          stack: e.stack,
          area: "FascicoloAdd",
          method: "handleBancaDatiBuyProduct/tsApiModeFinanceBuyProduct",
          version: sharedDatas.getPropertyByName("version"),
        });
        enqueueSnackbar(
          "Nessun documento trovato per il codice fiscale " +
            currentAnagrafica.cf,
          { variant: "error" }
        );
      }

      if (modeFinResponse !== null) {
        const orderId = modeFinResponse.data.order_id;
        const status = modeFinResponse.data.status;

        setBancaDatiAbbProductBought(orderId !== "" && status === "COMPLETE");
        setBancaDatiCredits(bancaDatiAbbCredits - item.price);

        //Recupero il prodotto appena acquistato
        const modeFinDocResponse = await tsApiModeFinanceGetDocument.fetch({
          url:
            "aml:mode-finance:api://orders/" +
            orderId +
            "/documents/format/JSON?item_id=" +
            tenantId,
          headers: {
            Authorization: "Bearer " + accessToken,
            "X-App-Name": "TS420",
            "X-App-Version": "1.0.0",
            "X-Request-ID": uuidv4(),
            "X-Correlation-ID": uuidv4(),
            "Content-Type": "application/json",
            "X-Item-ID": tenantId,
            "X-User-ID": loggedUserId,
          },
        });

        const result = modeFinDocResponse.data.result;

        if (item.product === "NEGATIVITA") {
          setBancaDatiResult((prevState) => ({
            ...prevState,
            Negativita: true,
          }));
        } else if (item.product === "PARTECIPAZIONI") {
          setBancaDatiResult((prevState) => ({
            ...prevState,
            Partecipazioni: true,
          }));
        } else if (item.product === "STRUTTURA_SOCIETARIA") {
          setBancaDatiResult((prevState) => ({
            ...prevState,
            StrutturaSocietaria: true,
          }));
        }

        const anagBancaDatiResultArr = Object.assign(
          [],
          anagraficheBancaDatiList
        );
        anagBancaDatiResultArr.push(
          new anagraficaBancaDatiModel(
            0,
            currentAnagrafica.id,
            item.product,
            JSON.stringify(result),
            new Date(),
            loggedUserId
          )
        );
        setAnagraficheBancaDatiList(anagBancaDatiResultArr);

        //Salvo il risultato nella banca dati dell'anagrafica
        if (currentAnagrafica.id > 0) {
          const bancaDatiAnagResponse = await apiAddAnagraficaBancaDati.fetch({
            anagraficaId: currentAnagrafica.id,
            repository: item.product,
            infos: JSON.stringify(result),
          });
        }

        const arrItems = [];
        arrItems.push(
          new anagraficaBancaDatiModel(
            0,
            currentAnagrafica.id,
            item.product,
            JSON.stringify(result),
            new Date(),
            loggedUserId
          )
        );

        //Invio una notifica tramite pub/sub interno
        postal.publish({
          channel: "bancaDati",
          topic: "bancaDati.result",
          data: {
            result: arrItems,
          },
        });

        //Aggiorno la data di ultima modifica del fascicolo
        const updateFascicoloRespone = await apiUpdateFascicolo.fetch({
          id: selFascicolo.id,
          name: selFascicolo.name,
          date: selFascicolo.date,
          isPrivate: selFascicolo.isPrivate,
        });

        enqueueSnackbar(t("message.success"), { variant: "success" });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handleBancaDatiBuyProduct",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const handleDetailBancaDatiClick = async (bancaDatiResultToShow) => {
    const itemToShowList = anagraficheBancaDatiList.filter(
      (item) => item.repository === bancaDatiResultToShow
    );

    if (itemToShowList.length > 0) {
      const itemToShow = itemToShowList[0];

      setSelBancaDatiItem(itemToShow);

      if (
        itemToShow.infos !== "" &&
        itemToShow.infos !== undefined &&
        itemToShow.infos !== null
      ) {
        if (
          itemToShow.repository !== "NEGATIVITA" &&
          itemToShow.repository !== "PARTECIPAZIONI" &&
          itemToShow.repository !== "STRUTTURA_SOCIETARIA"
        ) {
          let keys = null;
          const values = JSON.parse(itemToShow.infos);

          let valuesObj = null;
          if (values !== "") {
            valuesObj = JSON.parse(values);
            keys = Object.keys(valuesObj);
          }
          setSelBancaDatiItemValues(valuesObj);

          const arrKeys = [];
          keys.forEach((key) => {
            arrKeys.push(key);
          });
          setSelBancaDatiItemKeys(arrKeys);
          setShowModalDetail(true);
        } else if (itemToShow.repository === "NEGATIVITA") {
          const jsonRes = JSON.parse(itemToShow.infos);
          setBancaDatiAbbResultListNeg((prevState) => ({
            ...prevState,
            BLP: jsonRes.bankruptcy_liquidation_proceedings,
            DEB: jsonRes.detrimental_behaviors,
            DIB: jsonRes.dishonored_bills,
          }));
          setShowModalDetail(true);
        } else if (itemToShow.repository === "PARTECIPAZIONI") {
          const jsonRes = JSON.parse(itemToShow.infos);
          setBancaDatiAbbResultListPar((prevState) => ({
            ...prevState,
            PAR1: jsonRes.raw_data.partecipazioni_societa,
            PAR2: jsonRes.raw_data.tabella_partecipate_impresa_list,
          }));
          setShowModalDetail(true);
        } else if (itemToShow.repository === "STRUTTURA_SOCIETARIA") {
          const jsonRes = JSON.parse(itemToShow.infos);
          setBancaDatiAbbResultListStr((prevState) => ({
            ...prevState,
            CAR: jsonRes.cariche,
            SOC: jsonRes.soci,
            SIN: jsonRes.sindaci_organi_controllo,
          }));
          setShowModalDetail(true);
        }
      }
    }
  };
  const handleModalClose = () => {
    setSelBancaDatiItem(new bancaDatiModel(0, "", "", "", "", "", null, ""));
    setBancaDatiResult((prevState) => ({
      ...prevState,
      Pep_Regionali: false,
      Pep_Metropolitani: false,
      Pep_Provinciali: false,
      Pep_Comunali: false,
      Pep_MaggiorOrgano: false,
      Pep_OrganiStraordinari: false,
      Pep_SindaciInCarica: false,
      Pep_Comune: false,
      UNSC: false,
      OFAC: false,
      UE: false,
    }));
    setSelBancaDatiItemValues([]);
    setSelBancaDatiItemKeys(null);
    setBancaDatiAbbResultListNeg((prevState) => ({
      ...prevState,
      BLP: [],
      DEB: [],
      DIB: [],
    }));
    setBancaDatiAbbResultListPar((prevState) => ({
      ...prevState,
      PAR1: [],
      PAR2: [],
    }));
    setBancaDatiAbbResultListStr((prevState) => ({
      ...prevState,
      CAR: [],
      SOC: [],
      SIN: [],
    }));
    setShowModalDetail(false);
  };

  //Rimango in ascolto per reperire le anagrafiche di cui visualizzare le informazioni
  const getAnagrafiche = postal.subscribe({
    channel: "fascicolo",
    topic: "anagrafica.list",
    callback: function (data, envelope) {
      const anagrafiche = data.anagrafiche;
      const bancheDati = data.bancheDati;
      setCurrentAnagraficheList(anagrafiche);
      setAnagraficheBancaDatiList(bancheDati);
    },
  });
  const getSelectedAnagrafica = postal.subscribe({
    channel: "fascicolo",
    topic: "anagrafica.detail",
    callback: function (data, envelope) {
      const anagrafica = data.anagrafica;
      setCurrentAnagrafica(anagrafica);
    },
  });
  const getNewSos = postal.subscribe({
    channel: "fascicolo",
    topic: "sos.add",
    callback: function (data, envelope) {
      setSelectedTab("tabIndAnomalia");
      setOpenDrawer(true);
      setDrawerTabValue("sos");
      setDrawerHeaderTitle("SOS");
      setDrawerHeaderDesc("Segnalazioni di Operazioni Sospette");

      setCurrentSOS(
        new sosModel(
          0,
          new Date(),
          data.sosDesc,
          "",
          "",
          loggedUserId,
          new Date(),
          undefined,
          loggedUserId
        )
      );
      setCurrentOriginalSOS(
        new sosModel(
          0,
          new Date(),
          data.sosDesc,
          "",
          "",
          loggedUserId,
          new Date(),
          undefined,
          loggedUserId
        )
      );
    },
  });

  //Stampa documenti
  const printList = [
    { id: "istruttoria", name: "Istruttoria Cliente" },
    { id: "informativa", name: "Informativa Privacy" },
    {
      id: "astensione",
      name: "Dichiarazione di astensione del professionista",
    },
    { id: "dicCliente", name: "Dichiarazione del cliente" },
    {
      id: "dicProf",
      name: "Dichiarazione del professionista attestante ex art. 26 d.lgs. 231/2007",
    },
    {
      id: "valRischi",
      name: "Determinazione del rischio effettivo e della tipologia di adeguata verifica",
    },
    { id: "indAnomalia", name: "Indicatori di anomalia" },
  ];
  const [selectedPrint, setSelectedPrint] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPrestToPrint, setSelectedPrestToPrint] = useState([]);
  const [selectedRischiToPrint, setSelectedRischiToPrint] = useState([]);
  const [selectedIndAnomaliaToPrint, setSelectedIndAnomaliaToPrint] = useState(
    []
  );
  const [selectedSOSToPrint, setSelectedSOSToPrint] = useState(null);
  const [selectedProfToPrint, setSelectedProfToPrint] = useState({
    date: new Date(),
    name: "",
    address: "",
  });

  const handlePrintChange = async (event, value) => {
    const { id } = event.target;

    if (id.indexOf("ddlPrint-option") !== -1 || id === "ddlPrint") {
      setSelectedUsers([]);
      setSelectedPrestToPrint([]);
      setSelectedRischiToPrint([]);
      setSelectedIndAnomaliaToPrint([]);
      setSelectedSOSToPrint(null);
      setSelectedProfToPrint([]);

      setSelectedPrint(value.id);
      // if (value.id !== "informativa" && value.id !== "dicProf" && value.id !== "valRischi") {
      //   await loadPrestazioniToSelect();
      // }
      // else if (value.id === "valRischi") {
      //   await loadAllRischiToSelect();
      // }
    } else if (
      id.indexOf("ddlPrintSOS-option") !== -1 ||
      id === "ddlPrintSOS"
    ) {
      setSelectedUsers([]);
      setSelectedPrestToPrint([]);
      setSelectedRischiToPrint([]);
      setSelectedIndAnomaliaToPrint([]);
      setSelectedSOSToPrint(null);
      setSelectedProfToPrint([]);

      setSelectedSOSToPrint(value);
    } else if (id === "txtPrintProfName") {
      selectedProfToPrint.name = event.target.value;
      setSelectedProfToPrint(selectedProfToPrint);
    } else if (id === "txtPrintProfAddress") {
      selectedProfToPrint.address = event.target.value;
      setSelectedProfToPrint(selectedProfToPrint);
    }
  };
  const handleUserChange = async (event, value) => {
    const { id } = event.target;

    if (id.indexOf("ddlUtenti-option") !== -1 || id === "chbDdlUtenti") {
      setSelectedUsers(value);
    }

    if (
      selectedPrint !== "informativa" &&
      selectedPrint !== "dicProf" &&
      selectedPrint !== "valRischi" &&
      selectedPrint !== "indAnomalia"
    ) {
      await loadPrestazioniToSelect(value);
    } else if (selectedPrint === "valRischi") {
      await loadAllRischiToSelect(value);
    } else if (selectedPrint === "indAnomalia") {
      await loadIndAnomaliaToSelect(value);
    }

    //await loadPrestazioniToSelect();
  };
  const handlePrestToPrintClick = async (item) => {
    let prestazioni = Object.assign([], selectedPrestToPrint);

    if (prestazioni.filter((x) => x.id === item.id).length <= 0) {
      prestazioni.push(item);
      setSelectedPrestToPrint(prestazioni);
    } else {
      const newPrestazioni = prestazioni.filter((x) => x.id !== item.id);
      prestazioni = newPrestazioni;
    }

    setSelectedPrestToPrint(prestazioni);

    if (
      prestazioni.length > 0 &&
      selectedPrint !== "informativa" &&
      selectedPrint !== "dicProf" &&
      selectedPrint !== "astensione" &&
      selectedPrint !== "dicCliente"
    ) {
      await loadRischiToSelect(prestazioni);
    } else {
      setRischiList([]);
      setSelectedRischiToPrint([]);
    }
  };
  const handleRischiToPrintClick = async (item) => {
    var rischi = Object.assign([], selectedRischiToPrint);

    if (rischi.filter((x) => x.id === item.id).length <= 0) {
      rischi.push(item);
    } else {
      const newRischi = rischi.filter((x) => x.id !== item.id);
      rischi = newRischi;
    }

    setSelectedRischiToPrint(rischi);
  };
  const handleSOSToPrintClick = async (item) => {
    if (selectedSOSToPrint === null) {
      setSelectedSOSToPrint(item);
    } else {
      if (selectedSOSToPrint.id !== item.id) {
        setSelectedSOSToPrint(item);
      } else {
        setSelectedSOSToPrint(null);
      }
    }
  };
  const handleIndAnomaliaToPrintClick = async (item) => {
    let indicatori = Object.assign([], selectedIndAnomaliaToPrint);

    if (indicatori.filter((x) => x.id === item.id).length <= 0) {
      indicatori.push(item);
      setSelectedIndAnomaliaToPrint(indicatori);
    } else {
      const newIndicatori = indicatori.filter((x) => x.id !== item.id);
      indicatori = newIndicatori;
    }

    setSelectedIndAnomaliaToPrint(indicatori);
  };
  const handlePrintChangeDate = (date) => {
    const newDate = new Date(date);
    setSelectedProfToPrint((prevState) => ({ ...prevState, date: newDate }));
  };

  const [prestazioniList, setPrestazioniList] = useState([]);
  const [rischiList, setRischiList] = useState([]);
  const [indAnomaliaList, setIndAnomaliaList] = useState([]);
  const loadPrestazioniToSelect = async (users) => {
    try {
      setBackDropInternalOpen(true);

      /**************************************/
      //Carico gli utenti
      // const usersResponse = await apiUsersByCompanyId.fetch({ tenantId: tenantId, aziendaId: aziendaId });
      // const arrUsers = [];
      // usersResponse.data.data.user.map(item =>
      //   arrUsers.push(new userModel(item.UserId, item.TenantId, item.Username, item.Email, item.Name, item.Surname, item.Name + " " + item.Surname, item.Type, item.Locale, item.TenantTipoId, item.IsRespAntiRic, item.base_tipo_tenant.Value, item.IsActive, item.IsDeleted, item.IsBanned, item.CreatedOn, item.Cf))
      // );
      // setUtentiList(arrUsers);

      //gli utenti originali li confronto con i professionisti indicati nel fascicolo
      //così da presentare l'elenco di questi ultimi
      // const newArrUsers = [];
      // arrUsers.forEach((item) => {
      //   selFascicolo.professionisti.forEach((item2) => {
      //     if (item.userId === item2.userId) {
      //       newArrUsers.push(item);
      //     }
      //   });
      // });

      // setUtentiList(newArrUsers);

      //Carico le prestazioni
      const prestFascResponse = await apiPrestazioniByFascicolo.fetch({
        fascicoloId: selFascicolo.id,
      });
      if (prestFascResponse.data.errors !== undefined) {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } else {
        const arrItems = [];
        prestFascResponse.data.data.prestazione.forEach((item) => {
          let itemModel = new prestazioneModel(
            item.Id,
            item.TipoKey,
            item.PrestazioneId,
            item.base_tipo_prestazione.Nome,
            item.base_tipo_prestazione.Rischio,
            item.base_tipo_prestazione.Regole,
            item.StartDate,
            item.EndDate,
            item.PaeseId,
            item.ProvinciaId,
            item.Provincia,
            item.CittaId,
            item.Citta,
            item.ScopoId,
            item.NaturaId,
            item.Descrizione,
            item.IsPrivate,
            item.CreatedOn,
            item.ModifiedOn,
            [],
            [],
            item.AstensioneMotivo,
            item.IsEsoneroObbAst,
            item.base_scopo_prestazione.Value,
            item.base_natura_prestazione.Value,
            [],
            0,
            item.CreatedBy,
            item.ModifiedBy
          );

          const arrItemsUsers = [];
          let includeInList = false;

          item.prestazione_users.forEach((itemU) => {
            if (!includeInList) {
              includeInList =
                users.filter((x) => x.userId === itemU.UserId).length > 0;
            }
            arrItemsUsers.push(itemU);
          });
          //includeInList = includeInList && item.rischios.length > 0;

          const arrItemsPagamenti = [];
          item.prestazione_pagamentos.forEach((itemP) => {
            arrItemsPagamenti.push(
              new prestazionePagamentoModel(
                itemP.Id,
                itemP.ValutaId,
                itemP.ValutaData,
                itemP.ValutaCambio,
                itemP.ValutaImporto,
                itemP.ImportoEuro,
                itemP.ModalitaPagamentoId,
                itemP.OrigineId,
                itemP.CreatedOn,
                itemP.ModifiedOn,
                itemP.base_valutum.IsoCode,
                itemP.base_mezzo_pagamento.Value,
                itemP.base_origine_fondi.Value,
                itemP.Data,
                itemP.CreatedBy,
                itemP.ModifiedBy
              )
            );
          });

          itemModel.professionisti = arrItemsUsers;
          itemModel.pagamenti = arrItemsPagamenti;

          if (includeInList) {
            arrItems.push(itemModel);
          }
        });
        let filteredPrest = []
        arrItems.forEach(item => {          
          if(item.isPrivate)
          {
            let profs = item.professionisti.map(prof=>prof.UserId)
            if(profs.includes(loggedUserId))
              filteredPrest.push(item)
          } else {
            filteredPrest.push(item)
          }
        })
        
        setPrestazioniList(filteredPrest);

        //Se c'è un solo record lo seleziono di default
        // if (arrItems.length == 1) {
        //   var prestazioni = Object.assign([], selectedPrestToPrint);
        //   prestazioni = arrItems;
        //   setSelectedPrestToPrint(prestazioni);
        //   //loadRischiToSelect(prestazioni);
        // }
        // var prestazioni = Object.assign([], selectedPrestToPrint);
        // prestazioni = arrItems;
        setSelectedPrestToPrint(filteredPrest);
        await loadRischiToSelect(filteredPrest);
      }

      // Filtro le SOS
      let newSOSList = [];
      for (let i = 0; i < filteredUtentiList.length; i++) {
        const sosItems = currentSOSList.filter(x => x.reportedByUser === filteredUtentiList[i].userId);
        
        for (let m = 0; m < sosItems.length; m++) {
          newSOSList.push(sosItems[m]);
        }
      }
      setFilteredSOSList(newSOSList);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "loadPrestazioniToSelect",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const loadRischiToSelect = async (prestazioni) => {
    try {
      setBackDropInternalOpen(true);    

      //Carico i rischi per ogni prestazione selezionata
      let arrItems = [];

      for (var i = 0; i < prestazioni.length; i++) {
        const prest = prestazioni[i];

        const rischiResponse = await apiRischiByPrestazione.fetch({
          prestazioneId: prest.id,
        });
        if (rischiResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          if (selectedPrint === "valRischi") {
            rischiResponse.data.data.rischio.forEach((item) => {
              let livelloS = t("valutazione.form.rischioInerenteLivello1");
              if (item.EfTotale >= 1 && item.EfTotale <= 1.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello1");
              } else if (item.EfTotale > 1.5 && item.EfTotale <= 2.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello2");
              } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello3");
              } else if (item.EfTotale > 3.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello4");
              }

              let adeguataVerificaS = t(
                "valutazione.form.adeguataVerificaLivello1"
              );
              // if (item.EfTotale >= 1.00 && item.effettivo <= 1.50) {
              //   adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
              // }
              // else if (item.EfTotale > 1.50 && item.EfTotale <= 2.50) {
              //   adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
              // }
              if (item.EfTotale >= 1.0 && item.effettivo <= 2.5) {
                adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello1"
                );
              } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
                adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello2"
                );
              } else if (item.effettivo > 3.5) {
                adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello3"
                );
              }

              let itemModel = new rischioModel(
                item.Id,
                new Date(item.Date).toLocaleDateString(),
                prest,
                item.SpA1Livello,
                item.SpA1_1,
                item.SpA1_2,
                item.SpA1_3,
                item.SpA1_4,
                item.SpA1_5,
                item.SpA1Altro,
                item.SpA1AltroNote,
                item.SpA2Livello,
                item.SpA2_1,
                item.SpA2_2,
                item.SpA2_3,
                item.SpA2Altro,
                item.SpA2AltroNote,
                item.SpA3Livello,
                item.SpA3_1,
                item.SpA3_2,
                item.SpA3_3,
                item.SpA3_4,
                item.SpA3Altro,
                item.SpA3AltroNote,
                item.SpA4Livello,
                item.SpA4_1,
                item.SpA4_2,
                item.SpA4Altro,
                item.SpA4AltroNote,
                item.SpASubTotale,
                item.SpB1Livello,
                item.SpB1_1,
                item.SpB1_2,
                item.SpB1_3,
                item.SpB1Altro,
                item.SpB1AltroNote,
                item.SpB2Livello,
                item.SpB2_1,
                item.SpB2_2,
                item.SpB2_3,
                item.SpB2_4,
                item.SpB2Altro,
                item.SpB2AltroNote,
                item.SpB3Livello,
                item.SpB3_1,
                item.SpB3_2,
                item.SpB3Altro,
                item.SpB3AltroNote,
                item.SpB4Livello,
                item.SpB4_1,
                item.SpB4_2,
                item.SpB4Altro,
                item.SpB4AltroNote,
                item.SpB5Livello,
                item.SpB5_1,
                item.SpB5_2,
                item.SpB5_3,
                item.SpB5Altro,
                item.SpB5AltroNote,
                item.SpB6Livello,
                item.SpB6_1,
                item.SpB6_2,
                item.SpB6_3,
                item.SpB6Altro,
                item.SpB6AltroNote,
                item.SpBSubTotale,
                item.SpC1_1_Livello,
                item.SpC1_2_Livello,
                item.SpC1_3_Livello,
                item.SpCSubTotale,
                item.EfRiTotale,
                item.EfSpTotale,
                item.EfTotale,
                item.EfAdeguataLivello,
                item.HasTabellaB,
                item.ProvidedBy,
                item.CreatedOn,
                item.ModifiedOn,
                "",
                livelloS,
                adeguataVerificaS,
                new Date(item.DataControlloCostante).toLocaleDateString(),
                item.CreatedBy,
                item.ModifiedBy
              );

              if (
                selectedUsers.filter((x) => x.userId === item.ProvidedBy)
                  .length > 0
              ) {
                arrItems.push(itemModel);
              }
            });

            //Se c'è un solo record lo seleziono di default
            if (arrItems.length == 1) {
              var rischi = Object.assign([], selectedRischiToPrint);
              rischi = arrItems;
              setSelectedRischiToPrint(rischi);
            }
          } else {
            if (rischiResponse.data.data.rischio.length > 0) {
              const item = rischiResponse.data.data.rischio[0];

              let livelloS = t("valutazione.form.rischioInerenteLivello1");
              if (item.EfTotale >= 1 && item.EfTotale <= 1.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello1");
              } else if (item.EfTotale > 1.5 && item.EfTotale <= 2.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello2");
              } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello3");
              } else if (item.EfTotale > 3.5) {
                livelloS = t("valutazione.form.rischioInerenteLivello4");
              }

              let adeguataVerificaS = t(
                "valutazione.form.adeguataVerificaLivello1"
              );
              // if (item.EfTotale >= 1.00 && item.effettivo <= 1.50) {
              //   adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
              // }
              // else if (item.EfTotale > 1.50 && item.EfTotale <= 2.50) {
              //   adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
              // }
              if (item.EfTotale >= 1.0 && item.effettivo <= 2.5) {
                adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello1"
                );
              } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
                adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello2"
                );
              } else if (item.effettivo > 3.5) {
                adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello3"
                );
              }

              arrItems.push(
                new rischioModel(
                  item.Id,
                  new Date(item.Date).toLocaleDateString(),
                  prest,
                  item.SpA1Livello,
                  item.SpA1_1,
                  item.SpA1_2,
                  item.SpA1_3,
                  item.SpA1_4,
                  item.SpA1_5,
                  item.SpA1Altro,
                  item.SpA1AltroNote,
                  item.SpA2Livello,
                  item.SpA2_1,
                  item.SpA2_2,
                  item.SpA2_3,
                  item.SpA2Altro,
                  item.SpA2AltroNote,
                  item.SpA3Livello,
                  item.SpA3_1,
                  item.SpA3_2,
                  item.SpA3_3,
                  item.SpA3_4,
                  item.SpA3Altro,
                  item.SpA3AltroNote,
                  item.SpA4Livello,
                  item.SpA4_1,
                  item.SpA4_2,
                  item.SpA4Altro,
                  item.SpA4AltroNote,
                  item.SpASubTotale,
                  item.SpB1Livello,
                  item.SpB1_1,
                  item.SpB1_2,
                  item.SpB1_3,
                  item.SpB1Altro,
                  item.SpB1AltroNote,
                  item.SpB2Livello,
                  item.SpB2_1,
                  item.SpB2_2,
                  item.SpB2_3,
                  item.SpB2_4,
                  item.SpB2Altro,
                  item.SpB2AltroNote,
                  item.SpB3Livello,
                  item.SpB3_1,
                  item.SpB3_2,
                  item.SpB3Altro,
                  item.SpB3AltroNote,
                  item.SpB4Livello,
                  item.SpB4_1,
                  item.SpB4_2,
                  item.SpB4Altro,
                  item.SpB4AltroNote,
                  item.SpB5Livello,
                  item.SpB5_1,
                  item.SpB5_2,
                  item.SpB5_3,
                  item.SpB5Altro,
                  item.SpB5AltroNote,
                  item.SpB6Livello,
                  item.SpB6_1,
                  item.SpB6_2,
                  item.SpB6_3,
                  item.SpB6Altro,
                  item.SpB6AltroNote,
                  item.SpBSubTotale,
                  item.SpC1_1_Livello,
                  item.SpC1_2_Livello,
                  item.SpC1_3_Livello,
                  item.SpCSubTotale,
                  item.EfRiTotale,
                  item.EfSpTotale,
                  item.EfTotale,
                  item.EfAdeguataLivello,
                  item.HasTabellaB,
                  item.ProvidedBy,
                  item.CreatedOn,
                  item.ModifiedOn,
                  "",
                  livelloS,
                  adeguataVerificaS,
                  new Date(item.DataControlloCostante).toLocaleDateString(),
                  item.CreatedBy,
                  item.ModifiedBy
                )
              );
            }
          }
        }
      }

      setRischiList(arrItems);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "loadRischiToSelect",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const loadAllRischiToSelect = async (users) => {
    try {
      setBackDropInternalOpen(true);

      const rischiResponse = await apiRischiByTenant.fetch({
        tenantId: tenantId,
        aziendaId: aziendaId,
        userId: loggedUserId,
      });
      if (rischiResponse.data.errors !== undefined) {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } else {
        const arrItems = [];

        const filteredRischi = rischiResponse.data.data.rischio.filter(
          (x) => x.prestazione.fascicolo.Id === selFascicolo.id
        );

        for (let i = 0; i < users.length; i++) {
          const userItem = users[i];

          const items = filteredRischi.filter(x => x.ProvidedBy === userItem.userId).length > 0 ? 
          filteredRischi.filter(x => x.ProvidedBy === userItem.userId) : 
          null;
          
          if(items){
            for (let i = 0; i < items.length; i++) {
              const item = items[i];            
              if (item !== null && arrItems.filter((x) => x.id === item.Id).length <= 0) {
                let prest = {
                  prestazioneNome: item.prestazione.base_tipo_prestazione.Nome,
                };
    
                let livelloS = t("valutazione.form.rischioInerenteLivello1");
                if (item.EfTotale >= 1 && item.EfTotale <= 1.5) {
                  livelloS = t("valutazione.form.rischioInerenteLivello1");
                } else if (item.EfTotale > 1.5 && item.EfTotale <= 2.5) {
                  livelloS = t("valutazione.form.rischioInerenteLivello2");
                } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
                  livelloS = t("valutazione.form.rischioInerenteLivello3");
                } else if (item.EfTotale > 3.5) {
                  livelloS = t("valutazione.form.rischioInerenteLivello4");
                }
    
                let adeguataVerificaS = t(
                  "valutazione.form.adeguataVerificaLivello1"
                );
                if (item.EfTotale >= 1.0 && item.effettivo <= 2.5) {
                  adeguataVerificaS = t(
                    "valutazione.form.adeguataVerificaLivello1"
                  );
                } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
                  adeguataVerificaS = t(
                    "valutazione.form.adeguataVerificaLivello2"
                  );
                } else if (item.effettivo > 3.5) {
                  adeguataVerificaS = t(
                    "valutazione.form.adeguataVerificaLivello3"
                  );
                }
    
                let itemModel = new rischioModel(
                  item.Id,
                  new Date(item.Date).toLocaleDateString(),
                  prest,
                  item.SpA1Livello,
                  item.SpA1_1,
                  item.SpA1_2,
                  item.SpA1_3,
                  item.SpA1_4,
                  item.SpA1_5,
                  item.SpA1Altro,
                  item.SpA1AltroNote,
                  item.SpA2Livello,
                  item.SpA2_1,
                  item.SpA2_2,
                  item.SpA2_3,
                  item.SpA2Altro,
                  item.SpA2AltroNote,
                  item.SpA3Livello,
                  item.SpA3_1,
                  item.SpA3_2,
                  item.SpA3_3,
                  item.SpA3_4,
                  item.SpA3Altro,
                  item.SpA3AltroNote,
                  item.SpA4Livello,
                  item.SpA4_1,
                  item.SpA4_2,
                  item.SpA4Altro,
                  item.SpA4AltroNote,
                  item.SpASubTotale,
                  item.SpB1Livello,
                  item.SpB1_1,
                  item.SpB1_2,
                  item.SpB1_3,
                  item.SpB1Altro,
                  item.SpB1AltroNote,
                  item.SpB2Livello,
                  item.SpB2_1,
                  item.SpB2_2,
                  item.SpB2_3,
                  item.SpB2_4,
                  item.SpB2Altro,
                  item.SpB2AltroNote,
                  item.SpB3Livello,
                  item.SpB3_1,
                  item.SpB3_2,
                  item.SpB3Altro,
                  item.SpB3AltroNote,
                  item.SpB4Livello,
                  item.SpB4_1,
                  item.SpB4_2,
                  item.SpB4Altro,
                  item.SpB4AltroNote,
                  item.SpB5Livello,
                  item.SpB5_1,
                  item.SpB5_2,
                  item.SpB5_3,
                  item.SpB5Altro,
                  item.SpB5AltroNote,
                  item.SpB6Livello,
                  item.SpB6_1,
                  item.SpB6_2,
                  item.SpB6_3,
                  item.SpB6Altro,
                  item.SpB6AltroNote,
                  item.SpBSubTotale,
                  item.SpC1_1_Livello,
                  item.SpC1_2_Livello,
                  item.SpC1_3_Livello,
                  item.SpCSubTotale,
                  item.EfRiTotale,
                  item.EfSpTotale,
                  item.EfTotale,
                  item.EfAdeguataLivello,
                  item.HasTabellaB,
                  item.ProvidedBy,
                  item.CreatedOn,
                  item.ModifiedOn,
                  "",
                  livelloS,
                  adeguataVerificaS,
                  new Date(item.DataControlloCostante).toLocaleDateString(),
                  item.CreatedBy,
                  item.ModifiedBy
                );
    
                arrItems.push(itemModel);            
              }
            }
          }
        }
        setRischiList(arrItems);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "loadAllRischiToSelect",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const loadIndAnomaliaToSelect = async (users) => {
    try {
      setBackDropInternalOpen(true);

      //Carico gli indicatori
      const indResponse = await apiIndicatoriByFascicolo.fetch({
        fascicoloId: selFascicolo.id,
      });
      if (indResponse.data.errors !== undefined) {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } else {
        const arrItems = [];

        for (
          let i = 0;
          i < indResponse.data.data.indicatore_anomalia.length;
          i++
        ) {
          const item = indResponse.data.data.indicatore_anomalia[i];
          let itemModel = new indicatoreAnomaliaModel(
            item.Id,
            item.Date,
            item.ProvidedBy,
            JSON.parse(item.Result),
            item.Note,
            item.CreatedOn,
            item.ModifiedOn,
            item.CreatedBy,
            item.ModifiedBy
          );

          let includeInList =
            users.filter((x) => x.userId === item.ProvidedBy).length > 0;

          if (includeInList) {
            arrItems.push(itemModel);
          }
        }

        setIndAnomaliaList(arrItems);
        setSelectedIndAnomaliaToPrint(arrItems);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "loadIndAnomaliaToSelect",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const handlePrintClick = async () => {
    try {
      setBackDropInternalOpen(true);

      var canIProceed = false;

      if (selectedPrint === "istruttoria") {
        canIProceed = selectedPrestToPrint.length > 0;
      } else if (selectedPrint === "informativa") {
        canIProceed = true;
      } else if (selectedPrint === "astensione") {
        // canIProceed = selectedPrestToPrint.length > 0 && selectedSOSToPrint !== null;
        canIProceed = selectedPrestToPrint.length > 0;
      } else if (selectedPrint === "dicCliente") {
        canIProceed = selectedPrestToPrint.length > 0;
      } else if (selectedPrint === "dicProf") {
        canIProceed =
        selectedProfToPrint !== null && 
        selectedProfToPrint.date !== undefined &&
        selectedProfToPrint.name !== undefined && selectedProfToPrint.name !== "" &&
        selectedProfToPrint.address !== undefined && selectedProfToPrint.address !== "";
      } else if (selectedPrint === "valRischi") {
        canIProceed = selectedRischiToPrint.length > 0;
      } else if (selectedPrint === "indAnomalia") {
        canIProceed = selectedIndAnomaliaToPrint !== null;
      }

      if (canIProceed) {
        var reportTypeName = "";
        var reportFileName = "";
        var data = null;

        const aziendaInfos = sharedDatas
          .getPropertyByName("aziendaModelList")
          .filter(
            (x) => x.id === sharedDatas.getPropertyByName("aziendaId")
          )[0];
        const tenant = {
          tenantId: aziendaInfos.id,
          name: aziendaInfos.denominazione,
          cf: aziendaInfos.cf,
          pIva: aziendaInfos.pIva,
          address:
            aziendaInfos.addrIndirizzo +
            ", " +
            aziendaInfos.addrN +
            " - " +
            aziendaInfos.addrCap +
            " " +
            aziendaInfos.addrComune +
            " (" +
            aziendaInfos.addrProvincia +
            ")",
          city: aziendaInfos.addrComune,
        };

        //Recupero le info dell'utente
        const userById = sharedDatas.getPropertyByName("userModel");
        var professionista = {
          name: userById.name + " " + userById.surname,
          albo: {
            city: "",
            number: "",
          },
        };

        //Recupero le info del cliente
        const anagResponse = await apiAnagraficaByFascicolo.fetch({
          fascicoloId: selFascicolo.id,
        });
        const anagrafica =
          anagResponse.data.data.anagrafica.length > 0
            ? anagResponse.data.data.anagrafica
            : null;

        //Cliente
        const tipoAnagId1 = baseTipoAnagList.filter(
          (x) => x.key == "tipo_anagrafica_1"
        )[0].id;
        const tipoAnagId2 = baseTipoAnagList.filter(
          (x) => x.key == "tipo_anagrafica_2"
        )[0].id;
        const cliente = anagrafica.filter(
          (x) =>
            x.IsDeleted === false &&
            x.anagrafica_fascicolos.some(
              (y) =>
                y.FascicoloId === selFascicolo.id &&
                (y.TipoAnagId === tipoAnagId1 || y.TipoAnagId === tipoAnagId2)
            )
        )[0];

        //Delegati
        const tipoAnagId3 = baseTipoAnagList.filter(
          (x) => x.key == "tipo_anagrafica_3"
        )[0].id;
        const tipoAnagId4 = baseTipoAnagList.filter(
          (x) => x.key == "tipo_anagrafica_4"
        )[0].id;
        const correlate = anagrafica.filter(
          (x) =>
            x.IsDeleted === false &&
            x.anagrafica_fascicolos.some(
              (y) =>
                (y.FascicoloId === selFascicolo.id &&
                  y.TipoAnagId === tipoAnagId3) ||
                y.TipoAnagId === tipoAnagId4
            )
        );
        const correlateDistinct = [];
        for (let i = 0; i < correlate.length; i++) {
          const correlateDistinctItem = correlate[i];
          if (correlateDistinctItem.IsPg) {
            if (
              correlateDistinct.filter(
                (x) =>
                  x.Cf === correlateDistinctItem.Cf &&
                  x.PIva === correlateDistinctItem.PIva
              ).length <= 0
            ) {
              correlateDistinct.push(correlateDistinctItem);
            }
          } else {
            if (
              correlateDistinct.filter((x) => x.Cf === correlateDistinctItem.Cf)
                .length <= 0
            ) {
              correlateDistinct.push(correlateDistinctItem);
            }
          }
        }

        //Titolari effettivi
        const tipoAnagId5 = baseTipoAnagList.filter(
          (x) => x.key == "tipo_anagrafica_5"
        )[0].id;
        const titEff = anagrafica.filter(
          (x) =>
            x.IsDeleted === false &&
            x.anagrafica_fascicolos.some(
              (y) =>
                y.FascicoloId === selFascicolo.id &&
                y.TipoAnagId === tipoAnagId5
            )
        );
        const titEffDistinct = [];
        for (let i = 0; i < titEff.length; i++) {
          const titEffDistinctItem = titEff[i];
          if (titEffDistinctItem.IsPg) {
            if (
              titEffDistinct.filter(
                (x) =>
                  x.Cf === titEffDistinctItem.Cf &&
                  x.PIva === titEffDistinctItem.PIva
              ).length <= 0
            ) {
              titEffDistinct.push(titEffDistinctItem);
            }
          } else {
            if (
              titEffDistinct.filter((x) => x.Cf === titEffDistinctItem.Cf)
                .length <= 0
            ) {
              titEffDistinct.push(titEffDistinctItem);
            }
          }
        }

        const customerName = cliente.IsPg
          ? cliente.Denominazione
          : cliente.Nome + " " + cliente.Cognome;
        const customerPfRelation = baseTipoAnagList.filter(
          (x) => x.id === cliente.anagrafica_fascicolos[0].TipoAnagId
        )[0].value;

        //Identificazioni
        var identificazione = {
          identifiedBy: "",
          identificationType: "",
          identificationDate: null,
        };
        const hasIdentificazione =
          cliente.anagrafica_identificaziones.length > 0;
        let isIdentifiedByCurrentUser = true;
        if (hasIdentificazione) {
          const identItem = cliente.anagrafica_identificaziones[0];
          isIdentifiedByCurrentUser =
            identItem.IdentifiedByUser === loggedUserId;

          const identProf = utentiList.filter(
            (x) => x.userId === identItem.IdentifiedByUser
          )[0];
          if (!isIdentifiedByCurrentUser) {
            identificazione = {
              identifiedBy: identProf.name + " " + identProf.surname,
              identificationType: identItem.base_tipo_identificazione.Value,
              identificationDate: identItem.IdentifiedOn,
            };
          } else {
            identificazione = {
              identifiedBy: professionista.name,
              identificationType: identItem.base_tipo_identificazione.Value,
              identificationDate: identItem.IdentifiedOn,
            };
          }
        }

        switch (selectedPrint) {
          case "istruttoria": {
            reportTypeName = "comm_istruttoria_cliente";
            reportFileName = "Istruttoria Cliente";

            const delegati = [];
            correlateDistinct.forEach((item) => {
              delegati.push({
                name: item.Nome + " " + item.Cognome,
                cf: item.Cf,
                carica:
                  item.anagrafica_fascicolos[0].base_tipo_anagrafica.Value,
                pep: item.IsPep,
                pepCarica: item.PepCarica,
                notPepMoreThanYear: item.IsNotPepMoreThanYear
              });
            });

            const titolari = [];
            titEffDistinct.forEach((item) => {
              titolari.push({
                name: item.Nome + " " + item.Cognome,
                cf: item.Cf,
                carica:
                  item.anagrafica_fascicolos[0].base_tipo_anagrafica.Value,
                pep: item.IsPep,
                pepCarica: item.PepCarica,
                notPepMoreThanYear: item.IsNotPepMoreThanYear
              });
            });

            for (var i = 0; i < selectedPrestToPrint.length; i++) {
              const prest = selectedPrestToPrint[i];

              const users = [];
              for (var l = 0; l < prest.professionisti.length; l++) {
                users.push({
                  name: utentiList.filter(
                    (x) => x.userId === prest.professionisti[l].UserId
                  )[0].completeName,
                });
              }

              for (var l = 0; l < prest.professionisti.length; l++) {
                const prof = prest.professionisti[l];

                if (
                  selectedUsers.filter((x) => x.userId === prof.UserId).length >
                  0
                ) {
                  professionista = {
                    name: selectedUsers.filter(
                      (x) => x.userId === prof.UserId
                    )[0].completeName,
                    albo: {
                      city: "",
                      number: "",
                    },
                  };

                  let adeguataVerificaS = "";
                  let adeguataVerificaNMesi = 0;
                  let rischioEffettivoS = "";

                  if (
                    rischiList.filter((x) => x.prestazione.id === prest.id)
                      .length > 0
                  ) {
                    const rischioItem = rischiList.filter(
                      (x) => x.prestazione.id === prest.id
                    )[0];

                    adeguataVerificaS = t(
                      "valutazione.form.adeguataVerificaLivello1"
                    );
                    adeguataVerificaNMesi = 0;
                    if (
                      rischioItem.ef_total >= 1.0 &&
                      rischioItem.ef_total <= 1.5
                    ) {
                      adeguataVerificaS = t(
                        "valutazione.form.adeguataVerificaLivello1"
                      );
                      const baseGradoRischioItem = baseRischioGradoList.filter(
                        (x) =>
                          x.valoreRischioDa === 1.0 && x.valoreRischioA === 1.5
                      )[0];
                      adeguataVerificaNMesi = baseGradoRischioItem.offset;
                      // setRegoleAdeguataVerifica(baseGradoRischioItem.adeguataVerifica);
                    } else if (
                      rischioItem.ef_total > 1.5 &&
                      rischioItem.ef_total <= 2.5
                    ) {
                      adeguataVerificaS = t(
                        "valutazione.form.adeguataVerificaLivello1"
                      );
                      const baseGradoRischioItem = baseRischioGradoList.filter(
                        (x) =>
                          x.valoreRischioDa === 1.5 && x.valoreRischioA === 2.5
                      )[0];
                      adeguataVerificaNMesi = baseGradoRischioItem.offset;
                    } else if (
                      rischioItem.ef_total > 2.5 &&
                      rischioItem.ef_total <= 3.5
                    ) {
                      adeguataVerificaS = t(
                        "valutazione.form.adeguataVerificaLivello2"
                      );
                      const baseGradoRischioItem = baseRischioGradoList.filter(
                        (x) =>
                          x.valoreRischioDa === 2.5 && x.valoreRischioA === 3.5
                      )[0];
                      adeguataVerificaNMesi = baseGradoRischioItem.offset;
                    } else if (rischioItem.ef_total > 3.5) {
                      adeguataVerificaS = t(
                        "valutazione.form.adeguataVerificaLivello3"
                      );
                      const baseGradoRischioItem = baseRischioGradoList.filter(
                        (x) =>
                          x.valoreRischioDa === 3.5 && x.valoreRischioA === 4.0
                      )[0];
                      adeguataVerificaNMesi = baseGradoRischioItem.offset;
                    }

                    rischioEffettivoS = t(
                      "valutazione.form.adeguataVerificaLivello1"
                    );
                    if (
                      rischioItem.ef_total >= 1 &&
                      rischioItem.ef_total <= 1.5
                    ) {
                      rischioEffettivoS = t(
                        "valutazione.form.rischioInerenteLivello1"
                      );
                    } else if (
                      rischioItem.ef_total > 1.5 &&
                      rischioItem.ef_total <= 2.5
                    ) {
                      rischioEffettivoS = t(
                        "valutazione.form.rischioInerenteLivello2"
                      );
                    } else if (
                      rischioItem.ef_total > 2.5 &&
                      rischioItem.ef_total <= 3.5
                    ) {
                      rischioEffettivoS = t(
                        "valutazione.form.rischioInerenteLivello3"
                      );
                    } else if (rischioItem.ef_total > 3.5) {
                      rischioEffettivoS = t(
                        "valutazione.form.rischioInerenteLivello4"
                      );
                    }
                  }

                  var amount = 0;
                  var valuta = "";
                  var mezziPagamento = [];
                  for (var m = 0; m < prest.pagamenti.length; m++) {
                    const mezzo = prest.pagamenti[m];

                    amount = amount + mezzo.valutaImporto;
                    valuta = mezzo.valutaS;

                    mezziPagamento.push({
                      valuta: mezzo.valutaS,
                      valutaImporto: mezzo.valutaImporto.toLocaleString(
                        "it-IT",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      ),
                      modalitaPagamento: mezzo.modalitaPagamentoS,
                      origineFondi: mezzo.origineS,
                    });
                  }

                  data = {
                    tenant,
                    fascicolo: {
                      date: new Date(selFascicolo.date).toLocaleDateString(),
                      lastUpdatedDate:
                        selFascicolo.modifiedOn !== null
                          ? new Date(
                              selFascicolo.modifiedOn
                            ).toLocaleDateString()
                          : "",
                      owner: {
                        name: aziendaInfos.denominazione,
                      },
                      professionista: {
                        name: professionista.name,
                      },
                      // users: [
                      //   {
                      //     name: professionista.name
                      //   }
                      // ],
                      users,
                      anagrafica: {
                        isLegalEntity: cliente.IsPg,
                        name: customerName,
                        cf: cliente.Cf,
                        type: "new",
                        typeDesc: "Nuovo cliente",
                        identifiedBy: identificazione.identifiedBy,
                        identificationType: identificazione.identificationType,
                        delegati,
                        titolari,
                      },
                      prestazione: {
                        date: new Date(prest.dataStart).toLocaleDateString(),
                        amount: amount.toLocaleString("it-IT", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                        amountValuta: valuta,
                        type: {
                          name: prest.prestazioneNome,
                        },
                        scopo: prest.scopoS,
                        natura: prest.naturaS,
                        mezziPagamento,
                        rischio: {
                          adeguataVerifica: adeguataVerificaS,
                          livelloRischio: rischioEffettivoS,
                          controlloCostanteMesi: adeguataVerificaNMesi
                        }
                      }
                    }
                  }
                  
                  const body = {
                    template: {
                      name: reportTypeName,
                    },
                    data,
                  };

                  const docResponse = await apiGetJsReportDocumentAndSave.fetch(
                    { data: JSON.stringify(body) }
                  );
                  const displayedFileName =
                    reportFileName + " " + professionista.name + ".pdf";

                  const response = await apiAddAllegato.fetch({
                    tenantId: tenantId,
                    entityType: "fascicolo",
                    entityId: selFascicolo.id,
                    fileName:
                      docResponse.data.data.getJsReportDocumentAndSave.fileName,
                    fileType:
                      docResponse.data.data.getJsReportDocumentAndSave.fileType,
                    fileUrl:
                      docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                    displayedName: displayedFileName,
                  });

                  if (response.data.errors === undefined) {
                    const result = response.data.data.insert_allegato_one;
                    currentAllegati.push(
                      new allegatoModel(
                        result.Id,
                        tenantId,
                        result.EntityType,
                        result.EntityId,
                        result.FileName,
                        result.FileType,
                        result.FileUrl,
                        result.DisplayedName,
                        result.CreatedOn,
                        result.ModifiedOn,
                        result.CreatedBy,
                        result.ModifiedBy
                      )
                    );
                  }

                  //Apro il file in una nuova finestra
                  window.open(
                    docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                    "_blank",
                    "noopener,noreferrer"
                  );

                  if (!isIdentifiedByCurrentUser) {
                    const selectedProfToPrint = {
                      date: identificazione.identificationDate,
                      name: identificazione.identifiedBy,
                      address: "-",
                    };
                    await printDicProf(
                      tenant,
                      professionista,
                      customerName,
                      selectedProfToPrint,
                      cliente,
                      customerPfRelation
                    );
                  }
                }
              }
            }

            break;
          }
          case "informativa": {
            reportTypeName = "infprivacy";
            reportFileName = "Informativa Privacy";

            for (var i = 0; i < selectedUsers.length; i++) {
              professionista = {
                name: selectedUsers[i].completeName,
                albo: {
                  city: "",
                  number: "",
                },
              };

              data = {
                tenant,
                professionista,
                customer: {
                  name: customerName,
                },
                date: new Date(selFascicolo.date).toLocaleDateString(),
              };

              const body = {
                template: {
                  name: reportTypeName,
                },
                data,
              };

              const docResponse = await apiGetJsReportDocumentAndSave.fetch({
                data: JSON.stringify(body),
              });
              const displayedFileName =
                reportFileName + " " + professionista.name + ".pdf";

              const response = await apiAddAllegato.fetch({
                tenantId: tenantId,
                entityType: "fascicolo",
                entityId: selFascicolo.id,
                fileName:
                  docResponse.data.data.getJsReportDocumentAndSave.fileName,
                fileType:
                  docResponse.data.data.getJsReportDocumentAndSave.fileType,
                fileUrl:
                  docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                displayedName: displayedFileName,
              });

              if (response.data.errors === undefined) {
                const result = response.data.data.insert_allegato_one;
                currentAllegati.push(
                  new allegatoModel(
                    result.Id,
                    tenantId,
                    result.EntityType,
                    result.EntityId,
                    result.FileName,
                    result.FileType,
                    result.FileUrl,
                    result.DisplayedName,
                    result.CreatedOn,
                    result.ModifiedOn,
                    result.CreatedBy,
                    result.ModifiedBy
                  )
                );
              }

              //Apro il file in una nuova finestra
              window.open(
                docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                "_blank",
                "noopener,noreferrer"
              );
            }

            break;
          }
          case "astensione": {
            reportTypeName = "comm_astensione";
            reportFileName = "Astensione Professionista";

            for (var i = 0; i < selectedPrestToPrint.length; i++) {
              const prest = selectedPrestToPrint[i];

              for (var p = 0; p < prest.professionisti.length; p++) {
                const prof = prest.professionisti[p];

                if (selectedUsers.filter(x => x.userId === prof.UserId).length > 0) {
                  const profItem = selectedUsers.filter(x => x.userId === prof.UserId)[0];
                  const isSelectedSos = selectedSOSToPrint !== null && selectedSOSToPrint.id ? selectedSOSToPrint.reportedByUser === profItem.userId : false;

                  data = {
                    tenant,
                    professionista: {
                      name: profItem.name + " " + profItem.surname,
                      albo: {
                        city: "",
                        number: "",
                      },
                    },
                    customer: {
                      name: customerName,
                    },
                    prestazione: {
                      type: {
                        name: prest.prestazioneNome,
                      },
                      astensione: {
                        isAstensione: prest.isEsoneroObbAst,
                        motivo: prest.astensioneMotivo,
                      },
                      sos: {
                        isSos:
                          isSelectedSos,
                        motivo:
                          isSelectedSos ? selectedSOSToPrint.motivo : "",
                      },
                    },
                  };

                  const body = {
                    template: {
                      name: reportTypeName,
                    },
                    data,
                  };

                  const docResponse = await apiGetJsReportDocumentAndSave.fetch({
                    data: JSON.stringify(body),
                  });
                  const displayedFileName = reportFileName + "_" + profItem.name + "_" + profItem.surname + ".pdf";

                  const response = await apiAddAllegato.fetch({
                    tenantId: tenantId,
                    entityType: "fascicolo",
                    entityId: selFascicolo.id,
                    fileName:
                      docResponse.data.data.getJsReportDocumentAndSave.fileName,
                    fileType:
                      docResponse.data.data.getJsReportDocumentAndSave.fileType,
                    fileUrl:
                      docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                    displayedName: displayedFileName,
                  });

                  if (response.data.errors === undefined) {
                    const result = response.data.data.insert_allegato_one;
                    currentAllegati.push(
                      new allegatoModel(
                        result.Id,
                        tenantId,
                        result.EntityType,
                        result.EntityId,
                        result.FileName,
                        result.FileType,
                        result.FileUrl,
                        result.DisplayedName,
                        result.CreatedOn,
                        result.ModifiedOn,
                        result.CreatedBy,
                        result.ModifiedBy
                      )
                    );
                  }

                  //Apro il file in una nuova finestra
                  window.open(
                    docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }
              }
            }

            break;
          }
          case "dicCliente": {
            reportTypeName = "comm_dichiarazione_cliente";
            reportFileName = "Dichiarazione Cliente";

            const others = [];
            for (let i = 0; i < correlateDistinct.length; i++) {
              const item = correlateDistinct[i];
              const ident =
                item.anagrafica_identificaziones.length > 0
                  ? item.anagrafica_identificaziones[0]
                  : null;

              let docTipo = "-";
              let docN = "-";
              let docRelBy = "-";
              let docRelDate = "-";
              let docExpOn = "-";

              if (ident !== null) {
                docTipo = baseTipoDocList.filter(
                  (x) => x.id === ident.DocTipoId
                )[0].value;
                docN = ident.DocN;
                docRelBy = ident.DocRilDa;
                docRelDate = new Date(ident.DocRilData).toLocaleDateString();
                docExpOn = new Date(ident.DocScad).toLocaleDateString();
              }

              let othersAddrComune = "";
              let othersAddrProvincia = "";

              if (item.AddrComuneId > 0) {
                const comuneItem = await apiComuneById.fetch({
                  id: item.AddrComuneId,
                });
                othersAddrComune = comuneItem.data.data.base_comune_by_pk.Nome;
                othersAddrProvincia =
                  comuneItem.data.data.base_comune_by_pk.Provincia;
              }

              others.push({
                name: item.Nome + " " + item.Cognome,
                cf: item.Cf,
                job: item.Professione,
                bornLocation: item.NascitaLuogo,
                bornDate: new Date(item.NascitaData).toLocaleDateString(),
                carica:
                  item.anagrafica_fascicolos[0].base_tipo_anagrafica.Value,
                pep: item.IsPep,
                pepMoreThanYear: item.IsNotPepMoreThanYear,
                pepCarica: item.PepCarica,
                homeAddress:
                  item.AddrIndirizzo +
                  " " +
                  item.AddrN +
                  " - " +
                  item.AddrCap +
                  " " +
                  othersAddrComune +
                  " (" +
                  othersAddrProvincia +
                  ")",
                document: {
                  type: docTipo,
                  number: docN,
                  releasedBy: docRelBy,
                  releasedDate: docRelDate,
                  expiresOn: docExpOn,
                },
              });
            }

            const legRapp = others.length > 0 ? others.filter(x => x.carica === "Legale rappresentante")[0] : null;
            let person = null;
            
            if (cliente.IsPg === false) {
              let clienteAddrComune = "";
              let clienteAddrProvincia = "";

              if (cliente.AddrComuneId > 0) {
                const comuneItem = await apiComuneById.fetch({
                  id: cliente.AddrComuneId,
                });
                clienteAddrComune = comuneItem.data.data.base_comune_by_pk.Nome;
                clienteAddrProvincia =
                  comuneItem.data.data.base_comune_by_pk.Provincia;
              }
              
              
              person = {
                name: cliente.Denominazione,
                cf: cliente.Cf,
                job: cliente.Professione,
                bornLocation: cliente.NascitaLuogo,
                bornDate: `${cliente.NascitaData.split("-")[2].split("T")[0]}/${cliente.NascitaData.split("-")[1]}/${cliente.NascitaData.split("-")[0]}`,
                homeAddress: cliente.AddrIndirizzo +
                " " +
                cliente.AddrN +
                " - " +
                cliente.AddrCap +
                " " +
                clienteAddrComune +
                " (" +
                clienteAddrProvincia +
                ")",
                nationality: "Italiana",
                pep: {
                  isPep: cliente.IsPep,
                  isPepStatusOverYear: cliente.IsNotPepMoreThanYear,
                  roleCovered: cliente.PepCarica !== null ? cliente.PepCarica : "",
                },
                onBehalfOf: {
                  isHimself: false,
                  isOthers: false,
                  isCompany: cliente.IsPg,
                  others,
                },
              };
            }
            else {
              person = {
                name: legRapp.name,
                cf: legRapp.cf,
                job: legRapp.job,
                bornLocation: legRapp.bornLocation,
                bornDate: new Date(
                  legRapp.bornDate.split("/")[2],legRapp.bornDate.split("/")[1]-1,legRapp.bornDate.split("/")[0]
                ).toLocaleString().split(",")[0],
                homeAddress: legRapp.homeAddress,
                nationality: "Italiana",
                pep: {
                  isPep: legRapp.pep,
                  isPepStatusOverYear: legRapp.pepMoreThanYear,
                  roleCovered: legRapp.pepCarica !== null ? legRapp.pepCarica : "",
                },
                onBehalfOf: {
                  isHimself: false,
                  isOthers: false,
                  isCompany: cliente.IsPg,
                  others,
                },
              };
            }
            
            const titolari = [];
            for (let i = 0; i < titEffDistinct.length; i++) {
              const item = titEffDistinct[i];
              const ident =
                item.anagrafica_identificaziones.length > 0
                  ? item.anagrafica_identificaziones[0]
                  : null;

              const tipoPart = item.anagrafica_fascicolos.filter(
                (y) =>
                  y.FascicoloId === selFascicolo.id &&
                  y.TipoAnagId === tipoAnagId5
              )[0];
              const baseTipoPart = baseTipoPartList.filter(
                (x) => x.id === tipoPart.TipoPartId
              )[0];
              var tipoPartDesc = "";

              if (baseTipoPart.key === "tipo_partecipazione_10") {
                tipoPartDesc = tipoPart.TipoPartDesc;
              } else {
                tipoPartDesc = baseTipoPart.descrizione;
              }

              let docTipo = "-";
              let docN = "-";
              let docRelBy = "-";
              let docRelDate = "-";
              let docExpOn = "-";

              if (ident !== null) {
                docTipo = baseTipoDocList.filter(
                  (x) => x.id === ident.DocTipoId
                )[0].value;
                docN = ident.DocN;
                docRelBy = ident.DocRilDa;
                docRelDate = new Date(ident.DocRilData).toLocaleDateString();
                docExpOn = new Date(ident.DocScad).toLocaleDateString();
              }

              titolari.push({
                name: item.Nome + " " + item.Cognome,
                cf: item.Cf,
                job: item.Professione,
                bornLocation: item.NascitaLuogo,
                bornDate: item.NascitaData !== null
                  ? new Date(
                    item.NascitaData
                  ).toLocaleDateString()
                  : "-",
                carica:
                  item.anagrafica_fascicolos[0].base_tipo_anagrafica.Value,
                pep: item.IsPep,
                pepCarica: item.PepCarica,
                homeAddress:
                  item.AddrIndirizzo +
                  " " +
                  item.AddrN +
                  " - " +
                  item.AddrCap +
                  " " +
                  item.AddrComune +
                  " (" +
                  item.AddrProvincia +
                  ")",
                document: {
                  type: docTipo,
                  number: docN,
                  releasedBy: docRelBy,
                  releasedDate: docRelDate,
                  expiresOn: docExpOn,
                },
                tipoPartecipazione: tipoPartDesc,
              });
            }
            
            for (var i = 0; i < selectedPrestToPrint.length; i++) {
              const prest = selectedPrestToPrint[i];

              let origineFondi = "";
              let mezziPagamento = [];
              for (var m = 0; m < prest.pagamenti.length; m++) {
                const mezzo = prest.pagamenti[m];

                origineFondi = mezzo.origineS;

                mezziPagamento.push({
                  valuta: mezzo.valutaS,
                  valutaImporto: mezzo.valutaImporto.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  modalitaPagamento: mezzo.modalitaPagamentoS,
                  origineFondi: mezzo.origineS,
                });
              }

              // let clienteAddrComune = "";
              // let clienteAddrProvincia = "";

              // if (cliente.AddrComuneId > 0) {
              //   const comuneItem = await apiComuneById.fetch({
              //     id: cliente.AddrComuneId,
              //   });
              //   clienteAddrComune = comuneItem.data.data.base_comune_by_pk.Nome;
              //   clienteAddrProvincia =
              //     comuneItem.data.data.base_comune_by_pk.Provincia;
              // }

              data = {
                tenant,
                customer: {
                  isLegalEntity: cliente.IsPg,
                  identifiedOn:
                    identificazione.identificationDate !== null
                      ? new Date(
                          identificazione.identificationDate
                        ).toLocaleDateString()
                      : "-",
                  company: {
                    name: customerName,
                    cf: cliente.Cf,
                    address:
                      cliente.AddrIndirizzo +
                      " " +
                      cliente.AddrN +
                      " - " +
                      cliente.AddrCap +
                      " " +
                      cliente.AddrComune +
                      " (" +
                      cliente.AddrProvincia +
                      ")",
                    registry: {
                      city: cliente.ReaComune,
                      rea: cliente.ReaN,
                    },
                    relationType: customerPfRelation,
                    titolari,
                  },
                  // person: {
                  //   name: customerName,
                  //   cf: cliente.Cf,
                  //   job: cliente.Professione,
                  //   bornLocation: cliente.NascitaLuogo,
                  //   bornDate: new Date(
                  //     cliente.NascitaData
                  //   ).toLocaleDateString(),
                  //   homeAddress:
                  //     cliente.AddrIndirizzo +
                  //     " " +
                  //     cliente.AddrN +
                  //     " - " +
                  //     cliente.AddrCap +
                  //     " " +
                  //     clienteAddrComune +
                  //     " (" +
                  //     clienteAddrProvincia +
                  //     ")",
                  //   nationality: "",
                  //   pep: {
                  //     isPep: cliente.IsPep,
                  //     isPepStatusOverYear: cliente.IsNotPepMoreThanYear,
                  //     roleCovered: cliente.pepCarica,
                  //   },
                  //   onBehalfOf: {
                  //     isHimself: false,
                  //     isOthers: false,
                  //     isCompany: cliente.IsPg,
                  //     others,
                  //   },
                  // },
                  person,
                },
                prestazione: {
                  scopo: prest.scopoS,
                  natura: prest.naturaS,
                  origineFondi: origineFondi,
                  mezziPagamento,
                },
                luogo: aziendaInfos.addrComune
              };

              const body = {
                template: {
                  name: reportTypeName,
                },
                data,
              };

              const docResponse = await apiGetJsReportDocumentAndSave.fetch({
                data: JSON.stringify(body),
              });
              const displayedFileName = reportFileName + ".pdf";

              const response = await apiAddAllegato.fetch({
                tenantId: tenantId,
                entityType: "fascicolo",
                entityId: selFascicolo.id,
                fileName:
                  docResponse.data.data.getJsReportDocumentAndSave.fileName,
                fileType:
                  docResponse.data.data.getJsReportDocumentAndSave.fileType,
                fileUrl:
                  docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                displayedName: displayedFileName,
              });

              if (response.data.errors === undefined) {
                const result = response.data.data.insert_allegato_one;
                currentAllegati.push(
                  new allegatoModel(
                    result.Id,
                    tenantId,
                    result.EntityType,
                    result.EntityId,
                    result.FileName,
                    result.FileType,
                    result.FileUrl,
                    result.DisplayedName,
                    result.CreatedOn,
                    result.ModifiedOn,
                    result.CreatedBy,
                    result.ModifiedBy
                  )
                );
              }

              //Apro il file in una nuova finestra
              window.open(
                docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                "_blank",
                "noopener,noreferrer"
              );
            }

            break;
          }
          case "dicProf": {
            reportTypeName = "comm_dichiarazione_professionista";
            reportFileName = "Dichiarazione Professionista";

            for (var i = 0; i < selectedUsers.length; i++) {
              professionista = {
                name: selectedUsers[i].completeName,
                albo: {
                  city: "",
                  number: "",
                },
              };

              data = {
                tenant,
                professionista,
                from: {
                  date:
                    selectedProfToPrint.date === undefined
                      ? new Date().toLocaleDateString()
                      : new Date(selectedProfToPrint.date).toLocaleDateString(),
                  name: selectedProfToPrint.name,
                  address: selectedProfToPrint.address,
                  domicilio: aziendaInfos.addrIndirizzo + " " + aziendaInfos.addrN + " - " + aziendaInfos.addrCap + " " + aziendaInfos.addrComune + " (" + aziendaInfos.addrProvincia + ")",
                  soggettoComune: aziendaInfos.addrComune
                },
                customer: {
                  isLegalEntity: cliente.IsPg,
                  name: customerName,
                  cf: cliente.Cf,
                  carica: customerPfRelation,
                  identificationAtt: [
                    {
                      name: "Dati identificativi del cliente",
                    },
                  ],
                  dataIdent: cliente.anagrafica_identificaziones.length > 0 ? new Date(cliente.anagrafica_identificaziones[0].IdentifiedOn).toLocaleDateString() : new Date().toLocaleDateString()
                },
              };

              const body = {
                template: {
                  name: reportTypeName,
                },
                data,
              };

              const docResponse = await apiGetJsReportDocumentAndSave.fetch({
                data: JSON.stringify(body),
              });
              const displayedFileName =
                reportFileName + " " + professionista.name + ".pdf";

              const response = await apiAddAllegato.fetch({
                tenantId: tenantId,
                entityType: "fascicolo",
                entityId: selFascicolo.id,
                fileName:
                  docResponse.data.data.getJsReportDocumentAndSave.fileName,
                fileType:
                  docResponse.data.data.getJsReportDocumentAndSave.fileType,
                fileUrl:
                  docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                displayedName: displayedFileName,
              });

              if (response.data.errors === undefined) {
                const result = response.data.data.insert_allegato_one;
                currentAllegati.push(
                  new allegatoModel(
                    result.Id,
                    tenantId,
                    result.EntityType,
                    result.EntityId,
                    result.FileName,
                    result.FileType,
                    result.FileUrl,
                    result.DisplayedName,
                    result.CreatedOn,
                    result.ModifiedOn,
                    result.CreatedBy,
                    result.ModifiedBy
                  )
                );
              }

              //Apro il file in una nuova finestra
              window.open(
                docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                "_blank",
                "noopener,noreferrer"
              );
            }

            break;
          }
          case "valRischi": {
            reportTypeName = "comm_valrischio";
            reportFileName = "Valutazione dei rischi";

            for (var i = 0; i < selectedRischiToPrint.length; i++) {
              const rischio = selectedRischiToPrint[i];

              data = {
                tenant,
                customer: {
                  name: customerName,
                },
                prestazione: {
                  prestazioneNome: rischio.prestazione.prestazioneNome,
                },
                rischio,
              };

              const body = {
                template: {
                  name: reportTypeName,
                },
                data,
              };

              const docResponse = await apiGetJsReportDocumentAndSave.fetch({
                data: JSON.stringify(body),
              });
              const displayedFileName = reportFileName + ".pdf";

              const response = await apiAddAllegato.fetch({
                tenantId: tenantId,
                entityType: "fascicolo",
                entityId: selFascicolo.id,
                fileName:
                  docResponse.data.data.getJsReportDocumentAndSave.fileName,
                fileType:
                  docResponse.data.data.getJsReportDocumentAndSave.fileType,
                fileUrl:
                  docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                displayedName: displayedFileName,
              });

              if (response.data.errors === undefined) {
                const result = response.data.data.insert_allegato_one;
                currentAllegati.push(
                  new allegatoModel(
                    result.Id,
                    tenantId,
                    result.EntityType,
                    result.EntityId,
                    result.FileName,
                    result.FileType,
                    result.FileUrl,
                    result.DisplayedName,
                    result.CreatedOn,
                    result.ModifiedOn,
                    result.CreatedBy,
                    result.ModifiedBy
                  )
                );
              }

              //Apro il file in una nuova finestra
              window.open(
                docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                "_blank",
                "noopener,noreferrer"
              );
            }

            break;
          }
          case "indAnomalia": {
            reportTypeName = "indAnomalia";
            reportFileName = "Indicatori di anomalia";

            for (var i = 0; i < selectedIndAnomaliaToPrint.length; i++) {
              const indAnomalia = selectedIndAnomaliaToPrint[i];
              const professionista = selectedUsers.filter(
                (x) => x.userId === indAnomalia.providedBy
              )[0].completeName;

              const indAnomaliaResult = [];
              for (let m = 0; m < indAnomalia.result.length; m++) {
                const resultItem = indAnomalia.result[m];
                const point = parseInt(resultItem.id.split(".")[0]);
                const subPoint = parseInt(resultItem.id.split(".")[1]);

                const baseIndAnomaliaItem = baseIndAnomaliaList.filter(
                  (x) => x.point === point && x.subPoint === subPoint
                )[0];
                if (baseIndAnomaliaItem.isVisible) {
                  const newItem = {
                    section: baseIndAnomaliaItem.section,
                    point: point,
                    subPoint: subPoint,
                    description: baseIndAnomaliaList.filter(
                      (x) => x.point === point && x.subPoint === subPoint
                    )[0].description,
                    checked: resultItem.checked,
                  };

                  indAnomaliaResult.push(newItem);
                }
              }

              indAnomalia.date = new Date(
                indAnomalia.date
              ).toLocaleDateString();
              indAnomalia.result = indAnomaliaResult;

              const indAnomaliaSectionA = indAnomalia.result.filter(
                (x) => x.section === "A"
              );
              const indAnomaliaSectionB = indAnomalia.result.filter(
                (x) => x.section === "B"
              );
              const indAnomaliaSectionC = indAnomalia.result.filter(
                (x) => x.section === "C"
              );

              data = {
                tenant,
                indAnomalia,
                indAnomaliaSectionA,
                indAnomaliaSectionB,
                indAnomaliaSectionC,
                professionista,
              };

              const body = {
                template: {
                  name: reportTypeName,
                },
                data,
              };

              const docResponse = await apiGetJsReportDocumentAndSave.fetch({
                data: JSON.stringify(body),
              });
              const displayedFileName =
                reportFileName + " " + professionista + ".pdf";

              const response = await apiAddAllegato.fetch({
                tenantId: tenantId,
                entityType: "fascicolo",
                entityId: selFascicolo.id,
                fileName:
                  docResponse.data.data.getJsReportDocumentAndSave.fileName,
                fileType:
                  docResponse.data.data.getJsReportDocumentAndSave.fileType,
                fileUrl:
                  docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                displayedName: displayedFileName,
              });

              if (response.data.errors === undefined) {
                const result = response.data.data.insert_allegato_one;
                currentAllegati.push(
                  new allegatoModel(
                    result.Id,
                    tenantId,
                    result.EntityType,
                    result.EntityId,
                    result.FileName,
                    result.FileType,
                    result.FileUrl,
                    result.DisplayedName,
                    result.CreatedOn,
                    result.ModifiedOn,
                    result.CreatedBy,
                    result.ModifiedBy
                  )
                );
              }

              //Apro il file in una nuova finestra
              window.open(
                docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
                "_blank",
                "noopener,noreferrer"
              );
            }

            break;
          }
        }

        setSelectedPrint("");
        setSelectedUsers([]);
        setSelectedPrestToPrint([]);
        setSelectedRischiToPrint([]);
        setSelectedIndAnomaliaToPrint([]);
        setSelectedSOSToPrint(null);
        setPrestazioniList([]);
        setRischiList([]);
        setSelectedProfToPrint({ date: new Date(), name: "", address: "" });
      } else {
        enqueueSnackbar(t("error.missingFields"), { variant: "error" });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handlePrintClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const printDicProf = async (
    tenant,
    professionista,
    customerName,
    selectedProfToPrint,
    cliente,
    customerPfRelation
  ) => {
    try {
      const reportTypeName = "comm_dichiarazione_professionista";
      const reportFileName = "Dichiarazione Professionista";

      const data = {
        tenant,
        professionista,
        from: {
          date: new Date(selectedProfToPrint.date).toLocaleDateString(),
          name: selectedProfToPrint.name,
          address: selectedProfToPrint.address,
        },
        customer: {
          isLegalEntity: cliente.IsPg,
          name: customerName,
          cf: cliente.Cf,
          carica: customerPfRelation,
          identificationAtt: [
            {
              name: "Dati identificativi del cliente",
            },
          ],
        },
      };

      const body = {
        template: {
          name: reportTypeName,
        },
        data,
      };

      const docResponse = await apiGetJsReportDocumentAndSave.fetch({
        data: JSON.stringify(body),
      });
      const displayedFileName = reportFileName + ".pdf";

      const response = await apiAddAllegato.fetch({
        tenantId: tenantId,
        entityType: "fascicolo",
        entityId: selFascicolo.id,
        fileName: docResponse.data.data.getJsReportDocumentAndSave.fileName,
        fileType: docResponse.data.data.getJsReportDocumentAndSave.fileType,
        fileUrl: docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
        displayedName: displayedFileName,
      });

      if (response.data.errors === undefined) {
        const result = response.data.data.insert_allegato_one;
        currentAllegati.push(
          new allegatoModel(
            result.Id,
            tenantId,
            result.EntityType,
            result.EntityId,
            result.FileName,
            result.FileType,
            result.FileUrl,
            result.DisplayedName,
            result.CreatedOn,
            result.ModifiedOn,
            result.CreatedBy,
            result.ModifiedBy
          )
        );
      }

      //Apro il file in una nuova finestra
      window.open(
        docResponse.data.data.getJsReportDocumentAndSave.fileUrl,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "printDicProf",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  //Allegati
  const [allBrowserFiles, setAllBrowserFiles] = useState([]);
  var fileBase64 = [];
  const handleUploadeFile = async (file) => {
    setBackDropInternalOpen(true);

    try {
      var browserFiles = [];
      var encodedFiles;
      for (var i = 0; i < file["length"]; i++) {
        browserFiles.push(file[i]);
        encodedFiles = await encodeBase64(file[i]);
      }

      setAllBrowserFiles(browserFiles);

      const fileArr = Array.from(encodedFiles);

      //Solo se è un'anagrafica esistente procedo all'inserimento degli allegati
      //altrimenti l'inserimento viene effettuato al salvataggio dell'anagrafica
      if (selFascicolo.id > 0) {
        const allegati = Object.assign([], currentAllegati);

        for (var i = 0; i < fileArr.length; i++) {
          try {
            const file = browserFiles[i];
            const encFile = fileArr[i];
            const encFileBase64 = encFile.encrypt
              .substring(
                encFile.encrypt.indexOf("base64,"),
                encFile.encrypt.length
              )
              .split(",")[1];

            const displayedFileName = file.name;

            const fileJson = {
              file: encFileBase64,
              contentType: file.type,
            };

            const fileUrl = await apiUploadAllegatoToStorage.fetch({
              data: JSON.stringify(fileJson),
            });

            const response = await apiAddAllegato.fetch({
              tenantId: tenantId,
              entityType: "fascicolo",
              entityId: selFascicolo.id,
              fileName: fileUrl.data.data.uploadAttachment.fileName,
              fileType: file.type,
              fileUrl: fileUrl.data.data.uploadAttachment.path,
              displayedName: displayedFileName,
            });
            if (response.data.errors === undefined) {
              const result = response.data.data.insert_allegato_one;
              allegati.push(
                new allegatoModel(
                  result.Id,
                  tenantId,
                  result.EntityType,
                  result.EntityId,
                  result.FileName,
                  result.FileType,
                  result.FileUrl,
                  result.DisplayedName,
                  result.CreatedOn,
                  result.ModifiedOn,
                  result.CreatedBy,
                  result.ModifiedBy
                )
              );
            }
          } catch (e) {
            apiAddLog.fetch({
              tenantId: sharedDatas.getPropertyByName("tenantId"),
              aziendaId: sharedDatas.getPropertyByName("aziendaId"),
              level: "ERROR",
              message: e.message,
              stack: e.stack,
              area: "FascicoloAdd",
              method: "handleUploadeFile",
              version: sharedDatas.getPropertyByName("version"),
            });
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: selFascicolo.id,
          name: selFascicolo.name,
          date: selFascicolo.date,
          isPrivate: selFascicolo.isPrivate,
        });

        setCurrentAllegati(allegati);
        setAllBrowserFiles([]);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handleUploadeFile",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  async function encodeBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = async () => {
        var base64 = reader.result;

        fileBase64.push({ name: file.name, type: file.type, encrypt: base64 });
        resolve(fileBase64);
      };
    });
  }
  const handleDeleteAllegatoClick = (allegato) => {
    setIdToPerform(allegato.id);
    setTypeToPerform("ALL");
    setShowDeleteModal(true);
  };
  const handleDownloadAllegatoClick = (allegato) => {
    window.open(allegato.fileUrl, "_blank", "noopener,noreferrer");
  };

  //SOS
  const handleChangeSOS = (event, value) => {
    const { id } = event.target;
    
    if (id.indexOf("ddlReportedBy-option") !== -1 || id === "ddlReportedBy") {
      currentSOS.reportedByUser = value.userId;
    } else if (id === "txtReceiptId") {
      let value = event.target.value.toString()
      if(value.length >= 20){
        value = value.slice(0,20)
        enqueueSnackbar("Hai raggiunto il limite di caratteri",{variant:"info"})
      }
      currentSOS.receiptId = value;
    } else if (id === "txtMotivo") {
      currentSOS.motivo = event.target.value;
    } else if (id === "txtNote") {
      currentSOS.note = event.target.value;
    }

    setCurrentSOS(currentSOS);
  };
  const handleChangeDateSOS = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentSOS.date = date;
      setCurrentSOS((prevState) => ({ ...prevState, date: newDate }));
    }
  };
  const handleCancelSOS = () => {
    setCurrentSOS(
      new sosModel(
        0,
        new Date(),
        "",
        "",
        "",
        loggedUserId,
        new Date(),
        undefined,
        loggedUserId
      )
    );
  };
  const handleBtnAddSOSClick = async () => {
    try {
      setBackDropInternalOpen(true);

      //Se ne sto creando una
      if (currentSOS.id === 0) {
        const addSosResponse = await apiAddSos.fetch({
          fascicoloId: selFascicolo.id,
          date: currentSOS.date,
          motivo: currentSOS.motivo,
          note: currentSOS.note,
          receiptId: currentSOS.receiptId,
          reportedByUser: currentSOS.reportedByUser,
        });

        if (addSosResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          const result = addSosResponse.data.data.insert_sos_one;

          let sosResultModel = new sosModel(
            result.Id,
            result.Date,
            result.Motivo,
            result.Note,
            result.ReceiptId,
            result.ReportedByUser,
            result.CreatedOn,
            result.ModifiedOn,
            result.CreatedBy,
            result.ModifiedBy
          );

          //Aggiungo la Audit per tracciare l'azione
          const newValues = sosResultModel;
          apiAddAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            area: bsaSegnalazione,
            operation: "Create",
            entityType: "sos",
            entityId: result.Id.toString(),
            oldValues: "",
            newValues: JSON.stringify(newValues),
          });

          setCurrentSOS(
            new sosModel(
              0,
              new Date(),
              "",
              "",
              "",
              loggedUserId,
              new Date(),
              undefined,
              loggedUserId
            )
          );

          const sosList = Object.assign([], currentSOSList);
          sosList.push(sosResultModel);
          setCurrentSOSList(sosList);

          enqueueSnackbar(t("message.success"), { variant: "success" });
        }
      }

      //altrimenti la sto modificando
      else {
        const updateSosResponse = await apiUpdateSos.fetch({
          id: currentSOS.id,
          date: currentSOS.date,
          motivo: currentSOS.motivo,
          note: currentSOS.note,
          receiptId: currentSOS.receiptId,
          reportedByUser: currentSOS.reportedByUser,
        });

        if (updateSosResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          const result = updateSosResponse.data.data.update_sos.returning[0];

          let sosResultModel = new sosModel(
            result.Id,
            result.Date,
            result.Motivo,
            result.Note,
            result.ReceiptId,
            result.ReportedByUser,
            result.CreatedOn,
            result.ModifiedOn,
            result.CreatedBy,
            result.ModifiedBy
          );

          //Aggiungo la Audit per tracciare l'azione
          const newValues = sosResultModel;
          apiAddAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            area: bsaSegnalazione,
            operation: "Update",
            entityType: "sos",
            entityId: result.Id.toString(),
            oldValues: JSON.stringify(currentOriginalSOS),
            newValues: JSON.stringify(newValues),
          });

          setCurrentSOS(
            new sosModel(
              0,
              new Date(),
              "",
              "",
              "",
              loggedUserId,
              new Date(),
              undefined,
              loggedUserId
            )
          );

          enqueueSnackbar(t("message.success"), { variant: "success" });
        }
      }

      //Aggiorno la data di ultima modifica del fascicolo
      const updateFascicoloRespone = await apiUpdateFascicolo.fetch({
        id: selFascicolo.id,
        name: selFascicolo.name,
        date: selFascicolo.date,
        isPrivate: selFascicolo.isPrivate,
      });
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handleBtnAddSOSClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropInternalOpen(false);
    }
  };
  const handleSelectedSOSClick = async (sos) => {
    try {
      setCurrentSOS(sos);
      setCurrentOriginalSOS(sos);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handleSelectedSOSClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleDeleteSOSClick = (sos) => {
    setIdToPerform(sos.id);
    setTypeToPerform("SOS");
    setShowDeleteModal(true);
  };

  //Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setTypeToPerform("ALL");
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      if (idToPerform > 0) {
        setBackDropInternalOpen(true);

        if (typeToPerform === "ALL") {
          const response = await apiDeleteAllegato.fetch({ id: idToPerform });
          if (response.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Rimuovo il file da Azure
            await apiDeleteAllegatoFromStorage.fetch({
              fileName: currentAllegati.filter((x) => x.id === idToPerform)[0]
                .fileName,
            });

            //Aggiorno l'elenco degli allegati
            const allegati = Object.assign([], currentAllegati);
            const newAllegati = allegati.filter(
              (item) => item.id !== idToPerform
            );
            setCurrentAllegati(newAllegati);
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (typeToPerform === "SOS") {
          const response = await apiDeleteSos.fetch({ id: idToPerform });
          if (response.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiorno l'elenco delle SOS
            const sos = Object.assign([], currentSOSList);
            const newSos = sos.filter((item) => item.id !== idToPerform);
            setCurrentSOSList(newSos);
            setCurrentSOS(
              new sosModel(
                0,
                new Date(),
                "",
                "",
                "",
                loggedUserId,
                new Date(),
                undefined,
                loggedUserId
              )
            );
            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: selFascicolo.id,
          name: selFascicolo.name,
          date: selFascicolo.date,
          isPrivate: selFascicolo.isPrivate,
        });
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "handleModalDeleteOkClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setIdToPerform(0);
      setTypeToPerform("ALL");
      setShowDeleteModal(false);
      setBackDropInternalOpen(false);
    }
  };

  //Azioni che arrivano dal componente PrestazioneForm
  const handleGoToRischiPrestazione = (prestazione) => {
    setSelPrestazione(prestazione);
    setSelectedTab("tabRischio");
  };

  const handleReloadStatusFascicolo = async () => {
    try {
      const fascicoloResponse = await apiFascicoloById.fetch({
        id: selFascicolo.id,
      });
      const item = fascicoloResponse.data.data.fascicolo_by_pk;
      const status = await getFascicoloStatus(item);
      setStatusFascicolo(status.status);

      let statusReasons = "";
      if (!status.status) {
        for (let i = 0; i < status.reasons.length; i++) {
          statusReasons = statusReasons + status.reasons[i] + "\n";
        }
        setStatusFascicoloReasons(statusReasons);
      } else {
        setStatusFascicoloReasons("");
      }

      setSelFascicolo(
        new fascicoloModel(
          item.Id,
          item.TenantId,
          item.AziendaId,
          item.Name,
          item.Date,
          item.IsPrivate,
          item.CreatedOn,
          item.ModifiedOn,
          [],
          status,
          statusReasons,
          item.CreatedBy,
          item.ModifiedBy
        )
      );
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloAdd",
        method: "loadStatusFsacicolo",
        version: sharedDatas.getPropertyByName("version"),
      });
    }
  };

  //EuroConference
  const handleEuroConfClick = async (itemId) => {
    try {
      setBackDropOpen(true);

      const jsonToEnc = {
        id: itemId,
        search: "",
        userfullname: sharedDatas.getPropertyByName("userModel").username,
        context: "TS420",
        from: "TS Antiriciclaggio",
        userid: loggedUserId,
        teamsystemid: sharedDatas.getPropertyByName("userModel").email,
      };

      const cryptedContentResp = await tsApiAntiRic.fetch({
        url:
          "aml:ts-anti-ric:api://euroconf/getEncParam?valueToEnc=" +
          JSON.stringify(jsonToEnc),
      });

      window.open(
        "https://portale.ecevolution.it/ssoEvolution?encParam=" +
          cryptedContentResp.data,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaForm",
        method: "handleEuroConfClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  return (
    <Fragment>
      <VaporPage
        title={
          selFascicolo.name          
        }
        subtitle={
          selFascicolo.id === 0
             ? t("fascicolo.titoloAdd")
            : selFascicolo.modifiedOn !== null
              ? t("fascicolo.titoloManage") +
              " (ultima modifica: " +
              new Date(selFascicolo.modifiedOn).toLocaleDateString() +
              ")" : t("fascicolo.titoloManage")          
        }
        headerLeft={
          <Button
            variant='text'
            size='medium'
            color='primary'
            onClick={handleGoHome}
            sx={{ ml: 2 }}
          >
            {t("autovalutazione.autovalutazioneAdd.back")}
          </Button>
        }
        headerRight={
          isDataLoaded ? (
            statusFascicolo ? (
              <div
                style={{
                  ...(statusFascicolo === false ? divOrange : divGreen),
                  marginRight: "15px",
                }}
              >
                {statusFascicolo === false ? "Incompleto" : "Completo"}
              </div>
            ) : (
              <Tooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {statusFascicoloReasons}
                  </span>
                }
                arrow
                placement='left'
              >
                <div
                  style={{
                    ...(statusFascicolo === false ? divOrange : divGreen),
                    marginRight: "15px",
                  }}
                >
                  {statusFascicolo === false ? "Incompleto" : "Completo"}
                </div>
              </Tooltip>
            )
          ) : null
        }
      >
        <Box sx={{ display: "flex", position: "fixed", right: 0, top: "45%" }}>
          <Fragment key='right'>
            <ExtendedTabs
              variant='sticky'
              orientation='vertical'
              value={drawerTabValue}
            >
              <ExtendedTab
                value='bancaDati'
                onClick={() => openDrawerAction("bancaDati")}
                icon={
                  <Tooltip title='Banca dati' placement='left'>
                    <SearchIcon />
                  </Tooltip>
                }
                disabled={selectedTab !== "tabAnagrafica"}
              />
              <ExtendedTab
                value='allegati'
                onClick={() => openDrawerAction("allegati")}
                icon={
                  <Tooltip title='Allegati' placement='left'>
                    <AttachFileIcon />
                  </Tooltip>
                }
              />
              <ExtendedTab
                value='documenti'
                onClick={() => openDrawerAction("documenti")}
                icon={
                  <Tooltip title='Stampa' placement='left'>
                    <PrintIcon />
                  </Tooltip>
                }
              />
              {userPermissionsSOS.canRead ? (
                <ExtendedTab
                  value='sos'
                  onClick={() => openDrawerAction("sos")}
                  icon={
                    <Tooltip title='SOS' placement='left'>
                      <SosIcon />
                    </Tooltip>
                  }
                />
              ) : null}
            </ExtendedTabs>
          </Fragment>
          <Drawer
            anchor='right'
            open={openDrawer}
            onClose={handleDrawer}
            hideBackdrop
            width='45%'
            sx={{ zIndex: 1300 }}
          >
            <TabContext value={drawerTabValue}>
              <ExtendedTabs
                value={drawerTabValue}
                onChange={handleDrawerTabChange}
                orientation='vertical'
                sx={{
                  background: "white",
                  position: "fixed",
                  right: "45%",
                  top: "45%",
                }}
              >
                <ExtendedTab
                  value='bancaDati'
                  icon={
                    <Tooltip title='Banca dati' placement='left'>
                      <SearchIcon />
                    </Tooltip>
                  }
                  iconPosition='start'
                  disabled={selectedTab !== "tabAnagrafica"}
                />
                <ExtendedTab
                  value='allegati'
                  icon={
                    <Tooltip title='Allegati' placement='left'>
                      <AttachFileIcon />
                    </Tooltip>
                  }
                  iconPosition='start'
                />
                <ExtendedTab
                  value='documenti'
                  icon={
                    <Tooltip title='Stampa' placement='left'>
                      <PrintIcon />
                    </Tooltip>
                  }
                  iconPosition='start'
                />
                {userPermissionsSOS.canRead ? (
                  <ExtendedTab
                    value='sos'
                    icon={
                      <Tooltip title='SOS' placement='left'>
                        <SosIcon />
                      </Tooltip>
                    }
                    iconPosition='start'
                  />
                ) : null}
              </ExtendedTabs>

              <VaporHeaderBar
                divider
                title={drawerHeaderTitle}
                description={drawerHeaderDesc}
                rightItems={[
                  <IconButton onClick={handleDrawer} color='primary'>
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
              <TabPanel value='bancaDati'>
                <Loading open={backDropInternalOpen} />
                <ExtendedTabs
                  variant='standard'
                  size='small'
                  value={bancaDatiTabValue}
                  onChange={handleChangeBancaDatiTab}
                >
                  <ExtendedTab value='tabBdGratuite' label='Pubbliche' />
                  <ExtendedTab
                    value='tabBdPagamento'
                    label='Business Information'
                  />
                  <ExtendedTab value='tabBdResoconto' label='Riepilogo' />
                </ExtendedTabs>

                {bancaDatiTabValue === "tabBdGratuite" ? (
                  <Fragment>
                    <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
                      <Box flexGrow={1}></Box>
                      <Button
                        variant='contained'
                        size='small'
                        endIcon={<SearchIcon />}
                        onClick={handleCheckBancaDatiClick}
                        disabled={currentAnagrafica.cf === ""}
                      >
                        {t("fascicolo.fascicoloAdd.verifica")}
                      </Button>
                    </Stack>

                    <Divider variant='standard' sx={{ mt: 2, mb: 1 }} />

                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", mt: 4 }}
                    >
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='left' width='70%'>
                              <ExtendedTypography variant='subtitle2'>
                                {t("fascicolo.fascicoloAdd.bancaDati")}
                              </ExtendedTypography>
                            </TableCell>
                            <TableCell align='center' width='30%'>
                              <ExtendedTypography variant='subtitle2'>
                                {t("fascicolo.fascicoloAdd.esito")}
                              </ExtendedTypography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!currentAnagrafica.isPg ? (
                            <Fragment>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t(
                                      "fascicolo.fascicoloAdd.administratoriRaginali"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_Regionali
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_Regionali
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={bancaDatiResult.Pep_Regionali}
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Amministratori Regionali"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t(
                                      "fascicolo.fascicoloAdd.administratoriMetropolitani"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_Metropolitani
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_Metropolitani
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={
                                      bancaDatiResult.Pep_Metropolitani
                                    }
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Amministratori Metropolitani"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t(
                                      "fascicolo.fascicoloAdd.administratoriProvinciali"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_Provinciali
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_Provinciali
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={bancaDatiResult.Pep_Provinciali}
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Amministratori Provinciali"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t(
                                      "fascicolo.fascicoloAdd.administratoriComunali"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_Comunali
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_Comunali
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={bancaDatiResult.Pep_Comunali}
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Amministratori Comunali"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t("fascicolo.fascicoloAdd.maggiorOrgano")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_MaggiorOrgano
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_MaggiorOrgano
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={
                                      bancaDatiResult.Pep_MaggiorOrgano
                                    }
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Maggior Organo"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t(
                                      "fascicolo.fascicoloAdd.organiStraordinari"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_OrganiStraordinari
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_OrganiStraordinari
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={
                                      bancaDatiResult.Pep_OrganiStraordinari
                                    }
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Organi Straordinari in carica"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t("fascicolo.fascicoloAdd.sindaci")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_SindaciInCarica
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_SindaciInCarica
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={
                                      bancaDatiResult.Pep_SindaciInCarica
                                    }
                                    onClick={() =>
                                      handleDetailBancaDatiClick(
                                        "PEP - Sindaci in carica"
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow hover>
                                <TableCell align='left' width='70%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t(
                                      "fascicolo.fascicoloAdd.caricheComunali"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='30%'>
                                  <VaporTag
                                    variant='ghost'
                                    label={
                                      bancaDatiResult.Pep_Comune
                                        ? "Verifica completata"
                                        : "-"
                                    }
                                    type={
                                      bancaDatiResult.Pep_Comune
                                        ? "islamicGreen"
                                        : "blueSapphire"
                                    }
                                    clickable={bancaDatiResult.Pep_Comune}
                                    onClick={() =>
                                      handleDetailBancaDatiClick("PEP - Comune")
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          ) : null}
                          <TableRow hover>
                            <TableCell align='left' width='70%'>
                              <ExtendedTypography variant='subtitle2'>
                                {t("fascicolo.fascicoloAdd.unsc")}
                              </ExtendedTypography>
                            </TableCell>
                            <TableCell align='center' width='30%'>
                              <VaporTag
                                variant='ghost'
                                label={
                                  bancaDatiResult.UNSC
                                    ? "Verifica completata"
                                    : "-"
                                }
                                type={
                                  bancaDatiResult.UNSC
                                    ? "islamicGreen"
                                    : "blueSapphire"
                                }
                                clickable={bancaDatiResult.UNSC}
                                onClick={() =>
                                  handleDetailBancaDatiClick(
                                    "United Nations Security Council Consolidated List"
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow hover>
                            <TableCell align='left' width='70%'>
                              <ExtendedTypography variant='subtitle2'>
                                {t("fascicolo.fascicoloAdd.ofac")}
                              </ExtendedTypography>
                            </TableCell>
                            <TableCell align='center' width='30%'>
                              <VaporTag
                                variant='ghost'
                                label={
                                  bancaDatiResult.OFAC
                                    ? "Verifica completata"
                                    : "-"
                                }
                                type={
                                  bancaDatiResult.OFAC
                                    ? "islamicGreen"
                                    : "blueSapphire"
                                }
                                clickable={bancaDatiResult.OFAC}
                                onClick={() =>
                                  handleDetailBancaDatiClick(
                                    "OFAC Sanctions List"
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow hover>
                            <TableCell align='left' width='70%'>
                              <ExtendedTypography variant='subtitle2'>
                                {t("fascicolo.fascicoloAdd.sanctionsEU")}
                              </ExtendedTypography>
                            </TableCell>
                            <TableCell align='center' width='30%'>
                              <VaporTag
                                variant='ghost'
                                label={
                                  bancaDatiResult.UE
                                    ? "Verifica completata"
                                    : "-"
                                }
                                type={
                                  bancaDatiResult.UE
                                    ? "islamicGreen"
                                    : "blueSapphire"
                                }
                                clickable={bancaDatiResult.UE}
                                onClick={() =>
                                  handleDetailBancaDatiClick(
                                    "UE Sanctions List"
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Fragment>
                ) : null}

                {bancaDatiTabValue === "tabBdPagamento" ? (
                  <Fragment>
                    {hasBancaDatiAbb ? (
                      <Fragment>
                        <Card sx={{ mb: 1, mt: 2 }}>
                          <CardContent sx={{ p: 2 }}>
                            <Stack direction='column' spacing={2}>
                              <ExtendedTypography variant='titleSmall'>
                                {t("fascicolo.fascicoloAdd.credito")}{" "}
                                {bancaDatiAbbCredits}
                              </ExtendedTypography>
                            </Stack>
                          </CardContent>
                        </Card>
                        <TableContainer
                          component={Paper}
                          sx={{ width: "100%", mt: 4 }}
                        >
                          <Table size='small'>
                            <TableHead>
                              <TableRow>
                                <TableCell align='left' width='65%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t("fascicolo.fascicoloAdd.bancaDati")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='35%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {t("fascicolo.fascicoloAdd.esito")}
                                  </ExtendedTypography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {bancaDatiAbbProductsList.length > 0
                                ? bancaDatiAbbProductsList.map((item) => (
                                    <TableRow key={uuidv4()} hover>
                                      <TableCell align='left' width='65%'>
                                        {item.product === "NEGATIVITA" ? (
                                          <ExtendedTypography variant='subtitle2'>
                                            {t(
                                              "fascicolo.fascicoloAdd.negativita"
                                            )}
                                          </ExtendedTypography>
                                        ) : null}
                                        {item.product === "PARTECIPAZIONI" ? (
                                          <ExtendedTypography variant='subtitle2'>
                                            {t(
                                              "fascicolo.fascicoloAdd.partecipazioni"
                                            )}
                                          </ExtendedTypography>
                                        ) : null}
                                        {item.product ===
                                        "STRUTTURA_SOCIETARIA" ? (
                                          <ExtendedTypography variant='subtitle2'>
                                            {t(
                                              "fascicolo.fascicoloAdd.strutturaSocietaria"
                                            )}
                                          </ExtendedTypography>
                                        ) : null}
                                      </TableCell>
                                      <TableCell align='center' width='35%'>
                                        {bancaDatiAbbProductBought &&
                                        item.product === "NEGATIVITA" ? (
                                          <VaporTag
                                            variant='ghost'
                                            label={
                                              bancaDatiResult.Negativita
                                                ? "Disponibile"
                                                : "-"
                                            }
                                            type={
                                              bancaDatiResult.Negativita
                                                ? "islamicGreen"
                                                : "blueSapphire"
                                            }
                                            clickable={
                                              bancaDatiResult.Negativita
                                            }
                                            onClick={() =>
                                              handleDetailBancaDatiClick(
                                                item.product
                                              )
                                            }
                                          />
                                        ) : null}

                                        {bancaDatiAbbProductBought &&
                                        item.product === "PARTECIPAZIONI" ? (
                                          <VaporTag
                                            variant='ghost'
                                            label={
                                              bancaDatiResult.Partecipazioni
                                                ? "Disponibile"
                                                : "-"
                                            }
                                            type={
                                              bancaDatiResult.Partecipazioni
                                                ? "islamicGreen"
                                                : "blueSapphire"
                                            }
                                            clickable={
                                              bancaDatiResult.Partecipazioni
                                            }
                                            onClick={() =>
                                              handleDetailBancaDatiClick(
                                                item.product
                                              )
                                            }
                                          />
                                        ) : null}

                                        {bancaDatiAbbProductBought &&
                                        item.product ===
                                          "STRUTTURA_SOCIETARIA" ? (
                                          <VaporTag
                                            variant='ghost'
                                            label={
                                              bancaDatiResult.StrutturaSocietaria
                                                ? "Disponibile"
                                                : "-"
                                            }
                                            type={
                                              bancaDatiResult.StrutturaSocietaria
                                                ? "islamicGreen"
                                                : "blueSapphire"
                                            }
                                            clickable={
                                              bancaDatiResult.StrutturaSocietaria
                                            }
                                            onClick={() =>
                                              handleDetailBancaDatiClick(
                                                item.product
                                              )
                                            }
                                          />
                                        ) : null}

                                        {!bancaDatiAbbProductBought &&
                                        item.product === "NEGATIVITA" ? (
                                          <Button
                                            variant='contained'
                                            size='small'
                                            endIcon={<AddShoppingCartIcon />}
                                            onClick={() =>
                                              handleBancaDatiBuyProduct(item)
                                            }
                                            disabled={
                                              currentAnagrafica.cf === ""
                                            }
                                          >
                                            {t(
                                              "fascicolo.fascicoloAdd.acquistaPer"
                                            )}{" "}
                                            {item.price}{" "}
                                            {t(
                                              "fascicolo.fascicoloAdd.crediti"
                                            )}
                                          </Button>
                                        ) : null}

                                        {!bancaDatiAbbProductBought &&
                                        item.product === "PARTECIPAZIONI" ? (
                                          <Button
                                            variant='contained'
                                            size='small'
                                            endIcon={<AddShoppingCartIcon />}
                                            onClick={() =>
                                              handleBancaDatiBuyProduct(item)
                                            }
                                            disabled={
                                              currentAnagrafica.cf === ""
                                            }
                                          >
                                            {t(
                                              "fascicolo.fascicoloAdd.acquistaPer"
                                            )}{" "}
                                            {item.price}{" "}
                                            {t(
                                              "fascicolo.fascicoloAdd.crediti"
                                            )}
                                          </Button>
                                        ) : null}

                                        {!bancaDatiAbbProductBought &&
                                        item.product ===
                                          "STRUTTURA_SOCIETARIA" ? (
                                          <Button
                                            variant='contained'
                                            size='small'
                                            endIcon={<AddShoppingCartIcon />}
                                            onClick={() =>
                                              handleBancaDatiBuyProduct(item)
                                            }
                                            disabled={
                                              currentAnagrafica.cf === ""
                                            }
                                          >
                                            {t(
                                              "fascicolo.fascicoloAdd.acquistaPer"
                                            )}{" "}
                                            {item.price}{" "}
                                            {t(
                                              "fascicolo.fascicoloAdd.crediti"
                                            )}
                                          </Button>
                                        ) : null}

                                        {/* {bancaDatiAbbProductBought ? (
                                      <Chip variant="ghost" label={bancaDatiResult.Negativita ? "Disponibile" : "-"} type={bancaDatiResult.Negativita ? "islamicGreen" : "blueSapphire"} clickable={bancaDatiResult.Negativita} onClick={() => handleDetailBancaDatiClick(item.product)} />
                                    ) :
                                      <Button variant="contained" size="small" endIcon={<AddShoppingCartIcon />} onClick={() => handleBancaDatiBuyProduct(item)} disabled={currentAnagrafica.cf === ""}>Acquista per {item.price} crediti</Button>
                                    } */}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Fragment>
                    ) : (
                      <Card sx={{ mb: 1, mt: 2 }}>
                        <CardContent sx={{ p: 2 }}>
                          <Stack direction='column' spacing={2}>
                            <ExtendedTypography variant='titleSmall'>
                              {t("fascicolo.fascicoloAdd.bussinessInfo")}
                            </ExtendedTypography>
                            <ExtendedTypography variant='body'>
                              {t("fascicolo.fascicoloAdd.bussinessInfoDesc")}
                              <br />
                              <ul>
                                <li>
                                  {t(
                                    "fascicolo.fascicoloAdd.bussinessInfoDesc1"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "fascicolo.fascicoloAdd.bussinessInfoDesc2"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "fascicolo.fascicoloAdd.bussinessInfoDesc3"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "fascicolo.fascicoloAdd.bussinessInfoDesc4"
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "fascicolo.fascicoloAdd.bussinessInfoDesc5"
                                  )}
                                </li>
                              </ul>
                            </ExtendedTypography>
                            <Button
                              variant='contained'
                              size='small'
                              onClick={() =>
                                window.open(
                                  "https://www.teamsystem.com/store/business-information/",
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              {t("fascicolo.fascicoloAdd.service")}
                            </Button>
                          </Stack>
                        </CardContent>
                      </Card>
                    )}
                  </Fragment>
                ) : null}

                {bancaDatiTabValue === "tabBdResoconto" ? (
                  <Fragment>
                    {currentAnagraficheList.length > 0
                      ? currentAnagraficheList.map((item) => (
                          <Fragment key={item.id}>
                            <Stack
                              direction='column'
                              spacing={1}
                              sx={{ mt: 2, mb: 1 }}
                            >
                              <ExtendedTypography variant='titleSmall'>
                                {item.isPg
                                  ? item.denominazione
                                  : item.nome + " " + item.cognome}
                              </ExtendedTypography>
                              <Divider variant='standard' />
                            </Stack>

                            {anagraficheBancaDatiList.filter(
                              (x) =>
                                x.anagraficaId === item.id ||
                                x.anagraficaId === item.parentId
                            ).length > 0 ? (
                              <TableContainer
                                component={Paper}
                                sx={{ width: "100%", mt: 4 }}
                              >
                                <Table size='small'>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align='left' width='40%'>
                                        <ExtendedTypography variant='subtitle2'>
                                          {t(
                                            "fascicolo.fascicoloAdd.bancaDati"
                                          )}
                                        </ExtendedTypography>
                                      </TableCell>
                                      <TableCell align='left' width='30%'>
                                        <ExtendedTypography variant='subtitle2'>
                                          {t("fascicolo.fascicoloAdd.dati")}
                                        </ExtendedTypography>
                                      </TableCell>
                                      <TableCell align='center' width='30%'>
                                        <ExtendedTypography variant='subtitle2'>
                                          {t("fascicolo.fascicoloAdd.esito")}
                                        </ExtendedTypography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {anagraficheBancaDatiList
                                      .filter(
                                        (x) =>
                                          x.anagraficaId === item.id ||
                                          x.anagraficaId === item.parentId
                                      )
                                      .map((item2) => (
                                        <TableRow key={uuidv4()} hover>
                                          <TableCell align='left' width='40%'>
                                            <ExtendedTypography variant='subtitle2'>
                                              {item2.repository}
                                            </ExtendedTypography>
                                          </TableCell>
                                          <TableCell align='left' width='30%'>
                                            <ExtendedTypography variant='subtitle2'>
                                              {new Date(
                                                item2.createdOn
                                              ).toLocaleDateString()}
                                            </ExtendedTypography>
                                          </TableCell>
                                          <TableCell align='center' width='30%'>
                                            {item2.infos === "" ? (
                                              <VaporTag
                                                variant='ghost'
                                                label='Nessun risultato'
                                                type='islamicGreen'
                                              />
                                            ) : (
                                              <VaporTag
                                                variant='ghost'
                                                label='Visualizza'
                                                type='islamicGreen'
                                                clickable
                                                onClick={() =>
                                                  handleDetailBancaDatiClick(
                                                    item2.repository
                                                  )
                                                }
                                              />
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              <NoData size='small' />
                            )}
                          </Fragment>
                        ))
                      : null}
                  </Fragment>
                ) : null}
              </TabPanel>
              <TabPanel value='allegati'>
                <Loading open={backDropInternalOpen} />

                <Button
                  variant='text'
                  size='small'
                  startIcon={<img src='/img/EcLogo.png' width={20} />}
                  sx={{ width: "fit-content !important" }}
                  onClick={() => handleEuroConfClick(17270)}
                >
                  {t("fascicolo.fascicoloAdd.amlObligations")}
                </Button>
                <Button
                  variant='text'
                  size='small'
                  startIcon={<img src='/img/EcLogo.png' width={20} />}
                  sx={{ width: "fit-content !important" }}
                  onClick={() => handleEuroConfClick(21946)}
                >
                  {t("fascicolo.fascicoloAdd.amlRetentions")}
                </Button>
                <Button
                  variant='text'
                  size='small'
                  startIcon={<img src='/img/EcLogo.png' width={20} />}
                  sx={{ width: "fit-content !important" }}
                  onClick={() => handleEuroConfClick(21947)}
                >
                  {t("fascicolo.fascicoloAdd.amlRetentionObligations")}
                </Button>

                {userPermissions.canCreate ? (
                  <VaporUploader
                    buttonVariant='contained'
                    draggable
                    multiple
                    label=''
                    draggableText='Trascina qui i tuoi files'
                    dropText='Rilascia qui i tuoi files'
                    buttonLabel='Seleziona'
                    onFileUpload={handleUploadeFile}
                    files={allBrowserFiles}
                    inputFileProps={{
                      multiple: true,
                    }}
                  />
                ) : null}

                {currentAllegati.length > 0 ? (
                  <TableContainer>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='left' width='90%'>
                            <ExtendedTypography variant='subtitle2'>
                              {t("fascicolo.fascicoloAdd.doc")}
                            </ExtendedTypography>
                          </TableCell>
                          <TableCell align='center' width='10%'></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentAllegati.map((item) => (
                          <TableRow key={uuidv4()} hover>
                            <TableCell
                              align='left'
                              width='90%'
                              onClick={() => handleDownloadAllegatoClick(item)}
                              sx={{ cursor: "pointer" }}
                            >
                              <Stack
                                direction='row'
                                spacing={1}
                                justifyContent='left'
                                alignItems='center'
                              >
                                <FileIcon />
                                <Stack direction='column' spacing={0}>
                                  <ExtendedTypography variant='subtitle2'>
                                    {item.displayedName}
                                  </ExtendedTypography>
                                  <ExtendedTypography variant='caption'>
                                    {new Date(
                                      item.createdOn
                                    ).toLocaleDateString()}
                                  </ExtendedTypography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell align='center' width='10%'>
                              {userPermissions.canDelete ? (
                                <IconButton
                                  edge='end'
                                  aria-label='delete'
                                  color='error'
                                  onClick={() =>
                                    handleDeleteAllegatoClick(item)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData size='subtitle' />
                )}
              </TabPanel>
              <TabPanel value='documenti'>
                <Loading open={backDropInternalOpen} />

                <Stack direction='column' spacing={2}>
                  <Autocomplete
                    id='ddlPrint'
                    key={uuidv4()}
                    size='small'
                    options={printList}
                    sx={{ width: "100%" }}
                    fullWidth
                    onChange={handlePrintChange}
                    isOptionEqualToValue={(option, value) =>
                      option.id === selectedPrint
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      printList.filter((item) => item.id === selectedPrint)[0]
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label='Seleziona il documento da stampare'
                        sx={{ lineHeight: 1 }}
                      />
                    )}
                  />

                  <Autocomplete
                    id='ddlUtenti'
                    key={uuidv4()}
                    size='small'
                    fullWidth
                    multiple
                    options={filteredUtentiList}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.completeName}
                    isOptionEqualToValue={(option, value) =>
                      option.userId === value.userId
                    }
                    defaultValue={selectedUsers}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          id='chbDdlUtenti'
                          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                          checkedIcon={<CheckBoxIcon fontSize='small' />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.completeName}
                      </li>
                    )}
                    // getOptionDisabled={(option) =>
                    //   currentPrestazione.rischi.length > 0 && currentPrestazione.rischi.filter(x => x.ProvidedBy === option.userId).length > 0
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("prestazione.form.erogataDa")}
                        required
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    )}
                    renderTags={(params) =>
                      params.map((item) => (
                        <VaporTag
                          key={uuidv4()}
                          variant='standard'
                          type='blueSapphire'
                          label={item.completeName}
                          sx={{ mr: 1 }}
                        />
                      ))
                    }
                    onChange={(event, newValue, reason) => {
                      handleUserChange(event, newValue);
                      if (event.code !== "Backspace") {
                      }
                    }}
                  />

                  {selectedPrint !== "" &&
                  selectedPrint !== "informativa" &&
                  selectedPrint !== "dicProf" &&
                  selectedPrint !== "valRischi" &&
                  selectedPrint !== "indAnomalia" &&
                  selectedUsers.length > 0 ? (
                    <Fragment>
                      <ExtendedTypography variant='body' color='primary.main'>
                        {t("nav.prestazioni")} *
                      </ExtendedTypography>
                      <Divider variant='standard' />
                      <List dense disablePadding>
                        {prestazioniList.length > 0 ? (
                          prestazioniList.map((item) => (
                            <ListItem key={uuidv4()} disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                onClick={() => handlePrestToPrintClick(item)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    checked={
                                      selectedPrestToPrint.filter(
                                        (item2) => item2.id === item.id
                                      ).length > 0
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  key={uuidv4()}
                                  primary={
                                    new Date(
                                      item.dataStart
                                    ).toLocaleDateString() +
                                    " - " +
                                    item.prestazioneNome
                                  }
                                  secondary={item.professionisti.map(
                                    (prof) =>
                                      utentiList.filter(
                                        (x) => x.userId === prof.UserId
                                      )[0].completeName + " "
                                  )}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))
                        ) : (
                          <NoData size='small' />
                        )}
                      </List>
                    </Fragment>
                  ) : null}

                  {selectedPrint !== "" && selectedPrint === "valRischi" ? (
                    <Fragment>
                      <ExtendedTypography variant='body' color='primary.main'>
                        {t("nav.rischi")} *
                      </ExtendedTypography>
                      <Divider variant='standard' />
                      <List dense disablePadding>
                        {rischiList.length > 0 ? (
                          rischiList.map((item) => (
                            <ListItem key={uuidv4()} disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                onClick={() => handleRischiToPrintClick(item)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    checked={
                                      selectedRischiToPrint !== undefined &&
                                      selectedRischiToPrint.filter(
                                        (x) => x.id === item.id
                                      ).length > 0
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  key={uuidv4}
                                  primary={
                                    "Effettuata in data " +
                                    item.date +
                                    " da " +
                                    utentiList.filter(
                                      (x) => x.userId === item.providedBy
                                    )[0].completeName
                                  }
                                  secondary={
                                    "Rischio inerente:" +
                                    item.ef_ri_total +
                                    " | Rischio specifico: " +
                                    item.ef_sp_total +
                                    " | Totale: " +
                                    item.ef_total
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          ))
                        ) : (
                          <NoData size='small' />
                        )}
                      </List>
                    </Fragment>
                  ) : null}

                  {selectedPrint === "astensione" &&
                  selectedUsers.length > 0 ? (
                    <Fragment>
                      <ExtendedTypography variant='body' color='primary.main'>
                        {t("fascicolo.fascicoloAdd.suspiciousTransaction")}
                      </ExtendedTypography>
                      <Divider variant='standard' />

                      <List dense disablePadding>
                        {filteredSOSList.length > 0 ? (
                          filteredSOSList.map((item) => (
                            <ListItem key={uuidv4()} disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                onClick={() => handleSOSToPrintClick(item)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    checked={
                                      selectedSOSToPrint !== null &&
                                      selectedSOSToPrint.id === item.id
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={uuidv4}
                                  primary={new Date(
                                    item.date
                                  ).toLocaleDateString() + " - Prot. N. " + item.receiptId}
                                  secondary={utentiList.filter(
                                    (x) => x.userId === item.reportedByUser
                                  )[0].completeName}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))
                        ) : (
                          <NoData size='small' />
                        )}
                      </List>
                    </Fragment>
                  ) : null}

                  {selectedPrint === "dicProf" ? (
                    <Fragment>
                      <ExtendedTypography
                        variant='titleSmall'
                        color='primary.main'
                      >
                        Informazioni sul professionista attestante
                      </ExtendedTypography>
                      <Divider variant='standard' />

                      <Grid container>
                        <Grid item xs={3} sx={{ pr: 2 }}>
                          <Stack direction='row' spacing={2}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={it}
                            >
                              <DatePicker
                                label='Data'
                                closeOnSelect
                                format='dd/MM/yyyy'
                                mask='__/__/____'
                                maxDate={new Date()}
                                value={
                                  isDateValid(selectedProfToPrint.date)
                                    ? new Date(selectedProfToPrint.date)
                                    : null
                                }
                                onChange={handlePrintChangeDate}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    placeholder: "dd/MM/yyyy",
                                    size: "small",
                                    fullWidth : true
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                        <Grid item xs={5} sx={{ pr: 2 }}>
                          <TextField
                            id='txtPrintProfName'
                            key={uuidv4()}
                            label='Nome e Cognome'
                            size='small'
                            fullWidth
                            required
                            defaultValue={selectedProfToPrint.name}
                            onChange={handlePrintChange}
                            inputProps={{ autocomplete: "nope" }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id='txtPrintProfAddress'
                            key={uuidv4()}
                            label='Indirizzo Studio'
                            size='small'
                            fullWidth
                            required
                            defaultValue={selectedProfToPrint.address}
                            onChange={handlePrintChange}
                            inputProps={{ autocomplete: "nope" }}
                          />
                        </Grid>
                      </Grid>
                    </Fragment>
                  ) : null}

                  {selectedPrint === "indAnomalia" &&
                  selectedUsers.length > 0 ? (
                    <Fragment>
                      <ExtendedTypography variant='body' color='primary.main'>
                        {t("fascicolo.fascicoloAdd.indicatoriAnomalia")}
                      </ExtendedTypography>
                      <Divider variant='standard' />

                      <List dense disablePadding>
                        {indAnomaliaList.length > 0 ? (
                          indAnomaliaList.map((item) => (
                            <ListItem key={uuidv4()} disablePadding>
                              <ListItemButton
                                role={undefined}
                                dense
                                onClick={() =>
                                  handleIndAnomaliaToPrintClick(item)
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge='start'
                                    checked={
                                      selectedIndAnomaliaToPrint.filter(
                                        (item2) => item2.id === item.id
                                      ).length > 0
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={uuidv4}
                                  primary={
                                    "Effettuata in data " +
                                    new Date(item.date).toLocaleDateString() + 
                                    " da " +
                                    utentiList.filter(
                                      (x) => x.userId === item.providedBy
                                    )[0].completeName
                                  }
                                  secondary=''
                                />
                              </ListItemButton>
                            </ListItem>
                          ))
                        ) : (
                          <NoData size='small' />
                        )}
                      </List>
                    </Fragment>
                  ) : null}

                  <Button
                    variant='contained'
                    size='small'
                    endIcon={<PrintIcon />}
                    onClick={handlePrintClick}
                  >
                    {t("actions.stampa")}
                  </Button>
                </Stack>
              </TabPanel>
              {userPermissionsSOS.canRead ? (
                <TabPanel value='sos'>
                  <Loading open={backDropInternalOpen} />

                  <ExtendedTypography variant='titleSmall' color='primary.main'>
                    {t("fascicolo.fascicoloAdd.createNew")}
                  </ExtendedTypography>
                  <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />

                  <Stack direction='column' spacing={1}>
                    <Stack direction='row' spacing={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={it}
                      >
                        <DatePicker
                          label='Data'
                          closeOnSelect
                          format='dd/MM/yyyy'
                          mask='__/__/____'
                          maxDate={new Date()}
                          value={
                            isDateValid(currentSOS.date)
                              ? new Date(currentSOS.date)
                              : null
                          }
                          onChange={handleChangeDateSOS}
                          slotProps={{
                            textField: {
                              required: true,
                              placeholder: "dd/MM/yyyy",
                              size: "small",
                              fullWidth : true
                            },
                          }}
                        />
                      </LocalizationProvider>

                      <TextField
                        id='txtReceiptId'
                        key={uuidv4()}
                        label='Prot. N.'                        
                        defaultValue={currentSOS.receiptId}
                        size='small'
                        fullWidth
                        required
                        type="tel"
                        onChange={handleChangeSOS}
                        inputProps={{ maxLength:"20" }}
                      />

                      <Autocomplete
                        id='ddlReportedBy'
                        key={uuidv4()}
                        size='small'
                        options={utentiList}
                        sx={{ width: "100%" }}
                        fullWidth
                        onChange={handleChangeSOS}
                        isOptionEqualToValue={(option, value) =>
                          currentSOS.reportedByUser === undefined
                            ? option.userId === value.userId
                            : option.userId === currentSOS.reportedByUser
                        }
                        getOptionLabel={(option) => option.completeName}
                        defaultValue={
                          currentSOS.reportedByUser !== undefined
                            ? utentiList.filter(
                                (item) =>
                                  item.userId === currentSOS.reportedByUser
                              )[0]
                            : null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label='Segnalata da'
                            sx={{ lineHeight: 1 }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack direction='column' spacing={1}>
                      <TextField
                        id='txtMotivo'
                        key={uuidv4()}
                        label='Motivi del sospetto accertati/presunti'
                        defaultValue={currentSOS.motivo}
                        size='small'
                        fullWidth
                        multiline
                        rows={6}
                        onChange={handleChangeSOS}
                        inputProps={{ autocomplete: "nope" }}
                      />
                      <TextField
                        id='txtNote'
                        key={uuidv4()}
                        label='Note'
                        defaultValue={currentSOS.note}
                        size='small'
                        fullWidth
                        multiline
                        rows={2}
                        onChange={handleChangeSOS}
                        inputProps={{ autocomplete: "nope" }}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={1}
                      justifyContent='right'
                      alignItems='right'
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={handleCancelSOS}
                      >
                        {t("actions.annulla")}
                      </Button>
                      {userPermissionsSOS.canCreate ? (
                        <Button
                          variant='contained'
                          size='small'
                          onClick={handleBtnAddSOSClick}
                        >
                          {t("actions.salva")}
                        </Button>
                      ) : null}
                    </Stack>
                  </Stack>

                  <ExtendedTypography variant='titleSmall' color='primary.main'>
                    {t("fascicolo.fascicoloAdd.elenco")}
                  </ExtendedTypography>
                  <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />

                  {currentSOSList.length > 0 ? (
                    <Fragment>
                      <TableContainer
                        component={Paper}
                        sx={{ width: "100%", mt: 4 }}
                      >
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell align='left' width='25%'>
                                <ExtendedTypography variant='subtitle2'>
                                  {t("fascicolo.fascicoloAdd.dati")}
                                </ExtendedTypography>
                              </TableCell>
                              <TableCell align='left' width='25%'>
                                <ExtendedTypography variant='subtitle2'>
                                  {t("fascicolo.fascicoloAdd.protNr")}
                                </ExtendedTypography>
                              </TableCell>
                              <TableCell align='left' width='30%'>
                                <ExtendedTypography variant='subtitle2'>
                                  Effettuata da
                                </ExtendedTypography>
                              </TableCell>
                              <TableCell align='center' width='20%'></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentSOSList.map((item) => (
                              <TableRow
                                key={uuidv4()}
                                hover
                                onClick={() => handleSelectedSOSClick(item)}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell align='left' width='25%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {new Date(item.date).toLocaleDateString()}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='left' width='25%'>
                                  <ExtendedTypography variant='subtitle2'>
                                    {item.receiptId}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='left' width='25%'>
                                  <ExtendedTypography variant='subtitle2'>
                                  {utentiList.filter(
                                        (x) => x.userId === item.reportedByUser
                                      )[0].completeName + " "}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align='center' width='20%'>
                                  {userPermissionsSOS.canDelete ? (
                                    <IconButton
                                      onClick={() => handleDeleteSOSClick(item)}
                                      color='error'
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  ) : null}

                                  <IconButton
                                    onClick={() => handleSelectedSOSClick(item)}
                                    color='primary'
                                  >
                                    <DetailIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Fragment>
                  ) : (
                    <NoData size='small' />
                  )}
                </TabPanel>
              ) : null}
            </TabContext>
          </Drawer>
        </Box>

        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

        <Modal
          key='bancaDatiDetailModal'
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          open={showModalDetail}
          onClose={handleModalClose}
          onBackdropClick={handleModalClose}
        >
          <Box
            sx={{
              ...modalStyleLarge,
              pb: 2.5,
              minWidth: "700px",
              width: "50%",
              height: "520px",
            }}
          >
            {selBancaDatiItem.repository !== "NEGATIVITA" &&
            selBancaDatiItem.repository !== "PARTECIPAZIONI" &&
            selBancaDatiItem.repository !== "STRUTTURA_SOCIETARIA" &&
            selBancaDatiItem.repository !== "" &&
            selBancaDatiItem.infos !== "" ? (
              <Stack
                direction='column'
                spacing={0}
                justifyContent='left'
                alignItems='left'
              >
                <ExtendedTypography
                  variant='titleSmall'
                  color='primary.main'
                  sx={{ p: 2, pl: 3 }}
                >
                  {selBancaDatiItem.repository}
                </ExtendedTypography>
                <Divider orientation='horizontal' flexItem />

                <Box sx={{ p: 1, height: "450px", overflow: "auto" }}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {t("fascicolo.fascicoloAdd.identification")}
                          </TableCell>
                          <TableCell>
                            {selBancaDatiItem.identifier !== undefined &&
                            selBancaDatiItem.identifier !== null &&
                            selBancaDatiItem.identifier !== ""
                              ? selBancaDatiItem.identifier
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{t("fascicolo.form.nome")}</TableCell>
                          <TableCell>
                            {selBancaDatiItem.name !== undefined &&
                            selBancaDatiItem.name !== null &&
                            selBancaDatiItem.name !== ""
                              ? selBancaDatiItem.name
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {t("impostazioni.utenti.form.surname")}
                          </TableCell>
                          <TableCell>
                            {selBancaDatiItem.surname !== undefined &&
                            selBancaDatiItem.surname !== null &&
                            selBancaDatiItem.surname !== ""
                              ? selBancaDatiItem.surname
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {t("fascicolo.fascicoloAdd.dataNascita")}
                          </TableCell>
                          <TableCell>
                            {selBancaDatiItem.bornDate !== undefined &&
                            selBancaDatiItem.bornDate !== null &&
                            selBancaDatiItem.bornDate !== ""
                              ? new Date(selBancaDatiItem.bornDate)
                                  .toLocaleDateString()
                                  .slice(0, 10)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        {selBancaDatiItemKeys.map((key) => (
                          <TableRow
                            key={uuidv4()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{key}</TableCell>
                            <TableCell>
                              {selBancaDatiItemValues !== null
                                ? selBancaDatiItemValues[key].toString()
                                : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
            ) : null}

            {selBancaDatiItem.repository === "NEGATIVITA" &&
            (bancaDatiAbbResultListNeg.BLP.length > 0 ||
              bancaDatiAbbResultListNeg.DEB.length > 0 ||
              bancaDatiAbbResultListNeg.DIB.length > 0) ? (
              <Box sx={{ p: 3 }}>
                <Stack
                  direction='column'
                  spacing={0}
                  justifyContent='left'
                  alignItems='left'
                  sx={{ overflowY: "scroll", height: 500 }}
                >
                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pb: 1 }}
                  >
                    {t("fascicolo.fascicoloAdd.bacnkruptcy")}
                  </ExtendedTypography>

                  {bancaDatiAbbResultListNeg.BLP.map((item) => (
                    <ExtendedAccordion key={uuidv4()} sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ExtendedTypography
                          variant='titleSmall'
                          color='primary.main'
                          sx={{ width: "100% !important" }}
                        >
                          {item.event_description} | {item.event_date}{" "}
                          {item.closing_date !== null
                            ? "| Conclusa il" + item.closing_date
                            : null}
                        </ExtendedTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction='column' spacing={0}>
                          {item.judgment_number !== null ? (
                            <ExtendedTypography
                              variant='body'
                              color='primary.main'
                            >
                              {t("fascicolo.fascicoloAdd.judgment")}{" "}
                              {item.judgment_number}
                            </ExtendedTypography>
                          ) : null}
                          {item.tribunal !== null ? (
                            <ExtendedTypography
                              variant='body'
                              color='primary.main'
                            >
                              {t("fascicolo.fascicoloAdd.court")}{" "}
                              {item.tribunal}
                            </ExtendedTypography>
                          ) : null}
                        </Stack>
                        <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                        {item.charges.map((charge) => (
                          <Fragment key={uuidv4()}>
                            <Stack direction='column' spacing={0}>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {charge.fiscal_code}
                              </ExtendedTypography>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {charge.name} {charge.surname}
                              </ExtendedTypography>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {charge.charge_description}
                              </ExtendedTypography>
                            </Stack>
                            <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                          </Fragment>
                        ))}
                      </AccordionDetails>
                    </ExtendedAccordion>
                  ))}

                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ mt: 2, pb: 1 }}
                  >
                    {t("fascicolo.fascicoloAdd.ipoteche")}
                  </ExtendedTypography>

                  {bancaDatiAbbResultListNeg.DEB.map((item) => (
                    <ExtendedAccordion key={uuidv4()} sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ExtendedTypography
                          variant='titleSmall'
                          color='primary.main'
                          sx={{ width: "100% !important" }}
                        >
                          {item.act_description} | {item.act_date} |{" "}
                          {item.act_code}
                        </ExtendedTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction='column' spacing={0}>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.convervatoria")}{" "}
                            {item.conservatoria_description} -{" "}
                            {item.conservatoria_code}
                          </ExtendedTypography>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.submitedOn")}{" "}
                            {item.registration_date}{" "}
                            {t("fascicolo.fascicoloAdd.amount")}{" "}
                            {item.registered_amount}
                          </ExtendedTypography>
                        </Stack>

                        <ExtendedTypography variant='body' color='primary.main'>
                          {t("fascicolo.fascicoloAdd.beneficiari")}
                        </ExtendedTypography>
                        <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                        {item.beneficiaries.map((ben) => (
                          <Fragment key={uuidv4()}>
                            <Stack direction='column' spacing={0}>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {ben.first_name !== null &&
                                ben.last_name !== null
                                  ? ben.first_name + " " + ben.last_name
                                  : ben.name}
                              </ExtendedTypography>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {t("fascicolo.fascicoloAdd.cf")}{" "}
                                {ben.fiscal_code}
                              </ExtendedTypography>
                            </Stack>
                            <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                          </Fragment>
                        ))}

                        <ExtendedTypography variant='body' color='primary.main'>
                          {t("fascicolo.fascicoloAdd.debitori")}
                        </ExtendedTypography>
                        <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                        {item.debtors.map((deb) => (
                          <Fragment key={uuidv4()}>
                            <Stack direction='column' spacing={0}>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {deb.first_name !== null &&
                                deb.last_name !== null
                                  ? deb.first_name + " " + deb.last_name
                                  : deb.name}
                              </ExtendedTypography>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {t("fascicolo.fascicoloAdd.cf")}{" "}
                                {deb.fiscal_code}
                              </ExtendedTypography>
                            </Stack>
                            <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                          </Fragment>
                        ))}

                        <ExtendedTypography variant='body' color='primary.main'>
                          {t("fascicolo.fascicoloAdd.note")}
                        </ExtendedTypography>
                        <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />
                        {item.notes.length > 0
                          ? item.notes.map((note) => (
                              <Fragment key={uuidv4()}>
                                <Stack direction='column' spacing={0}>
                                  <ExtendedTypography
                                    variant='body'
                                    color='primary.main'
                                  >
                                    {note.act_description} del {note.act_date} |{" "}
                                    {note.specie_description}
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant='body'
                                    color='primary.main'
                                  >
                                    {note.official} - N. {note.inventory_number}{" "}
                                    {t("fascicolo.fascicoloAdd.del")}{" "}
                                    {note.inventory_date}
                                  </ExtendedTypography>
                                </Stack>
                                <Divider
                                  variant='standard'
                                  sx={{ mt: 1, mb: 1 }}
                                />
                              </Fragment>
                            ))
                          : "-"}
                      </AccordionDetails>
                    </ExtendedAccordion>
                  ))}

                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ mt: 2, pb: 1 }}
                  >
                    {t("fascicolo.fascicoloAdd.protesti")}
                  </ExtendedTypography>
                  {bancaDatiAbbResultListNeg.DIB.map((item) => (
                    <ExtendedAccordion key={uuidv4()} sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ExtendedTypography
                          variant='titleSmall'
                          color='primary.main'
                          sx={{ width: "100% !important" }}
                        >
                          {item.effect_type_description} | {item.detection_date}{" "}
                          | € {item.amount}
                        </ExtendedTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction='column' spacing={0}>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.provincia")}{" "}
                            {item.detection_province_description}
                          </ExtendedTypography>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.tipologia")}{" "}
                            {item.effect_type_description}
                          </ExtendedTypography>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.descrizione")}{" "}
                            {item.effect_status_description}
                          </ExtendedTypography>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.Mmtivazione")}{" "}
                            {item.non_payment_description}
                          </ExtendedTypography>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.registrato")}{" "}
                            {item.registration_date}{" "}
                            {t("fascicolo.fascicoloAdd.con")} N.:{" "}
                            {item.repertory_number}
                          </ExtendedTypography>
                        </Stack>
                      </AccordionDetails>
                    </ExtendedAccordion>
                  ))}
                </Stack>
              </Box>
            ) : null}

            {selBancaDatiItem.repository === "PARTECIPAZIONI" ? (
              <Box sx={{ p: 3 }}>
                <Stack
                  direction='column'
                  spacing={0}
                  justifyContent='left'
                  alignItems='left'
                  sx={{ overflowY: "scroll", height: 500 }}
                >
                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pb: 1 }}
                  >
                    {t("fascicolo.fascicoloAdd.partecipazioni")}
                  </ExtendedTypography>

                  {bancaDatiAbbResultListPar.PAR1.partecipazioni.partecipazione_list.map(
                    (item) => (
                      <ExtendedAccordion key={uuidv4()} sx={{ width: "100%" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <ExtendedTypography
                            variant='titleSmall'
                            color='primary.main'
                            sx={{ width: "100% !important" }}
                          >
                            {item.estremi_impresa.denominazione} | C.F.:{" "}
                            {item.estremi_impresa.c_fiscale}
                          </ExtendedTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack direction='column' spacing={0}>
                            {item.capitale_sociale !== null ? (
                              <Fragment>
                                <ExtendedTypography
                                  variant='titleSmall'
                                  color='primary.main'
                                  sx={{ pb: 1 }}
                                >
                                  {t("fascicolo.fascicoloAdd.capitaleSociale")}
                                </ExtendedTypography>
                                <ExtendedTypography
                                  variant='body'
                                  color='primary.main'
                                >
                                  {item.capitale_sociale.valuta}{" "}
                                  {item.capitale_sociale.ammontare}
                                </ExtendedTypography>
                                <Divider
                                  variant='standard'
                                  sx={{ mt: 1, mb: 1 }}
                                />
                              </Fragment>
                            ) : null}

                            <ExtendedTypography
                              variant='titleSmall'
                              color='primary.main'
                              sx={{ pb: 1 }}
                            >
                              {t("fascicolo.fascicoloAdd.estremiPratica")}
                            </ExtendedTypography>
                            <ExtendedTypography
                              variant='body'
                              color='primary.main'
                            >
                              {item.estremi_pratica.tipo_adempimento} n.{" "}
                              {item.estremi_pratica.n} CCIAA{" "}
                              {item.estremi_pratica.cciaa},{" "}
                              {t("fascicolo.fascicoloAdd.depositedOn")}{" "}
                              {item.estremi_pratica.dt_deposito}{" "}
                              {t("fascicolo.fascicoloAdd.recordedOn")}{" "}
                              {item.estremi_pratica.dt_protocollo}
                            </ExtendedTypography>
                            <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />

                            <ExtendedTypography
                              variant='titleSmall'
                              color='primary.main'
                              sx={{ pb: 1 }}
                            >
                              {t("fascicolo.fascicoloAdd.participationRights")}
                            </ExtendedTypography>
                            {item.riquadri.riquadro_list.map((item2) =>
                              item2.diritti_partecipazione.diritto_partecipazione_list.map(
                                (item3) => (
                                  <ExtendedTypography
                                    key={uuidv4()}
                                    variant='body'
                                    color='primary.main'
                                  >
                                    {item3.tipo}
                                  </ExtendedTypography>
                                )
                              )
                            )}
                          </Stack>
                        </AccordionDetails>
                      </ExtendedAccordion>
                    )
                  )}
                </Stack>
              </Box>
            ) : null}

            {selBancaDatiItem.repository === "STRUTTURA_SOCIETARIA" ? (
              <Box sx={{ p: 3 }}>
                <Stack
                  direction='column'
                  spacing={0}
                  justifyContent='left'
                  alignItems='left'
                  sx={{ overflowY: "scroll", height: 500 }}
                >
                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pb: 1 }}
                  >
                    {t("fascicolo.fascicoloAdd.strutturaSocietaria")}
                  </ExtendedTypography>

                  {bancaDatiAbbResultListStr.CAR.map((item) => (
                    <ExtendedAccordion key={uuidv4()} sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ExtendedTypography
                          variant='titleSmall'
                          color='primary.main'
                          sx={{ width: "100% !important" }}
                        >
                          {item.anagrafica_persona.info_persona.nominativo} |
                          {t("fascicolo.fascicoloAdd.cf")}{" "}
                          {item.anagrafica_persona.info_persona.codice_fiscale}
                        </ExtendedTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction='column' spacing={0}>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.bornIn")}{" "}
                            {
                              item.anagrafica_persona.info_persona.dati_nascita
                                .comune
                            }{" "}
                            (
                            {
                              item.anagrafica_persona.info_persona.dati_nascita
                                .provincia_sigla
                            }
                            ) {t("fascicolo.fascicoloAdd.il")}{" "}
                            {
                              item.anagrafica_persona.info_persona.dati_nascita
                                .giorno
                            }
                            /
                            {
                              item.anagrafica_persona.info_persona.dati_nascita
                                .mese
                            }
                            /
                            {
                              item.anagrafica_persona.info_persona.dati_nascita
                                .anno
                            }
                          </ExtendedTypography>
                          {item.anagrafica_persona.info_persona.residenza !==
                          null ? (
                            <ExtendedTypography
                              variant='body'
                              color='primary.main'
                            >
                              {t("fascicolo.fascicoloAdd.residenteIn")}{" "}
                              {
                                item.anagrafica_persona.info_persona.residenza
                                  .indirizzo
                              }{" "}
                              {
                                item.anagrafica_persona.info_persona.residenza
                                  .comune
                              }{" "}
                              (
                              {
                                item.anagrafica_persona.info_persona.residenza
                                  .provincia_sigla
                              }
                              )
                            </ExtendedTypography>
                          ) : null}
                          <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />

                          <ExtendedTypography
                            variant='titleSmall'
                            color='primary.main'
                            sx={{ pb: 1 }}
                          >
                            {t("fascicolo.fascicoloAdd.cariche")}
                          </ExtendedTypography>
                          {item.elenco_atti_conferimento_cariche.map((item2) =>
                            item2.elenco_cariche.map((item3) => (
                              <ExtendedTypography
                                key={uuidv4()}
                                variant='body'
                                color='primary.main'
                              >
                                {item3.tipo} {item3.durata}{" "}
                                {t("fascicolo.fascicoloAdd.appointedOn")}{" "}
                                {item3.data_atto_nomina_giorno}/
                                {item3.data_atto_nomina_mese}/
                                {item3.data_atto_nomina_anno}
                              </ExtendedTypography>
                            ))
                          )}
                        </Stack>
                      </AccordionDetails>
                    </ExtendedAccordion>
                  ))}

                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    sx={{ pb: 1, mt: 2 }}
                  >
                    {t("fascicolo.fascicoloAdd.soci")}
                  </ExtendedTypography>
                  {bancaDatiAbbResultListStr.SOC.map((item) => (
                    <ExtendedAccordion key={uuidv4()} sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ExtendedTypography
                          variant='titleSmall'
                          color='primary.main'
                          sx={{ width: "100% !important" }}
                        >
                          {item.anagrafica.nominativo} | C.F.:{" "}
                          {item.anagrafica.codice_fiscale} |{" "}
                          {item.percentuale_totale.toFixed(2).replace(".", ",")}
                          %
                        </ExtendedTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction='column' spacing={0}>
                          <ExtendedTypography
                            variant='body'
                            color='primary.main'
                          >
                            {t("fascicolo.fascicoloAdd.domiciliatoIn")}{" "}
                            {item.anagrafica.domicilio.indirizzo}{" "}
                            {item.anagrafica.domicilio.comune} (
                            {item.anagrafica.domicilio.provincia_sigla})
                          </ExtendedTypography>
                          <Divider variant='standard' sx={{ mt: 1, mb: 1 }} />

                          <ExtendedTypography
                            variant='titleSmall'
                            color='primary.main'
                            sx={{ pb: 1 }}
                          >
                            {t("fascicolo.fascicoloAdd.quote")}
                          </ExtendedTypography>
                          {item.elenco_quote.map((item2) => (
                            <ExtendedTypography
                              key={uuidv4()}
                              variant='body'
                              color='primary.main'
                            >
                              - {item2.tipo}{" "}
                              {t("fascicolo.fascicoloAdd.forThe")}{" "}
                              {item2.percentuale}%{" "}
                              {t("fascicolo.fascicoloAdd.nominalValue")}{" "}
                              {item2.valuta}{" "}
                              {item2.valore_nominale
                                .toFixed(2)
                                .replace(".", ",")}{" "}
                              {t("fascicolo.fascicoloAdd.whichPaid")}{" "}
                              {item2.valuta}{" "}
                              {item2.valore_versato
                                .toFixed(2)
                                .replace(".", ",")}
                            </ExtendedTypography>
                          ))}
                        </Stack>
                      </AccordionDetails>
                    </ExtendedAccordion>
                  ))}

                  {bancaDatiAbbResultListStr.SIN.length > 0 ? (
                    <Fragment>
                      <ExtendedTypography
                        variant='titleSmall'
                        color='primary.main'
                        sx={{ pb: 1, mt: 2 }}
                      >
                        {t("fascicolo.fascicoloAdd.sindicati")}
                      </ExtendedTypography>
                      {bancaDatiAbbResultListStr.SIN.map((item) => (
                        <ExtendedAccordion
                          key={uuidv4()}
                          sx={{ width: "100%" }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ExtendedTypography
                              variant='titleSmall'
                              color='primary.main'
                              sx={{ width: "100% !important" }}
                            >
                              {item.anagrafica_persona.info_persona.nominativo}{" "}
                              | C.F.:{" "}
                              {
                                item.anagrafica_persona.info_persona
                                  .codice_fiscale
                              }
                            </ExtendedTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction='column' spacing={0}>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {t("fascicolo.fascicoloAdd.bornIn")}{" "}
                                {
                                  item.anagrafica_persona.info_persona
                                    .dati_nascita.comune
                                }{" "}
                                (
                                {
                                  item.anagrafica_persona.info_persona
                                    .dati_nascita.provincia_sigla
                                }
                                ) il{" "}
                                {
                                  item.anagrafica_persona.info_persona
                                    .dati_nascita.giorno
                                }
                                /
                                {
                                  item.anagrafica_persona.info_persona
                                    .dati_nascita.mese
                                }
                                /
                                {
                                  item.anagrafica_persona.info_persona
                                    .dati_nascita.anno
                                }
                              </ExtendedTypography>
                              <ExtendedTypography
                                variant='body'
                                color='primary.main'
                              >
                                {t("fascicolo.fascicoloAdd.domiciliatoIn")}{" "}
                                {
                                  item.anagrafica_persona.domicilio_fiscale
                                    .indirizzo
                                }{" "}
                                {
                                  item.anagrafica_persona.domicilio_fiscale
                                    .comune
                                }{" "}
                                (
                                {
                                  item.anagrafica_persona.domicilio_fiscale
                                    .provincia_sigla
                                }
                                )
                              </ExtendedTypography>
                              <Divider
                                variant='standard'
                                sx={{ mt: 1, mb: 1 }}
                              />

                              <ExtendedTypography
                                variant='titleSmall'
                                color='primary.main'
                                sx={{ pb: 1 }}
                              >
                                {t("fascicolo.fascicoloAdd.cariche")}
                              </ExtendedTypography>
                              {item.elenco_atti_conferimento_cariche.map(
                                (item2) =>
                                  item2.elenco_cariche.map((item3) => (
                                    <ExtendedTypography
                                      key={uuidv4()}
                                      variant='body'
                                      color='primary.main'
                                    >
                                      - {item3.tipo} {item3.durata} nominato il{" "}
                                      {item3.data_atto_nomina_giorno}/
                                      {item3.data_atto_nomina_mese}/
                                      {item3.data_atto_nomina_anno}
                                    </ExtendedTypography>
                                  ))
                              )}
                              <Divider
                                variant='standard'
                                sx={{ mt: 1, mb: 1 }}
                              />

                              <ExtendedTypography
                                variant='titleSmall'
                                color='primary.main'
                                sx={{ pb: 1 }}
                              >
                                {t("fascicolo.fascicoloAdd.ruoli")}
                              </ExtendedTypography>
                              {item.elenco_ruoli_persona.map((item2) => (
                                <ExtendedTypography
                                  key={uuidv4()}
                                  variant='body'
                                  color='primary.main'
                                >
                                  - {item2.tipo} n. {item2.numero}{" "}
                                  {t("fascicolo.fascicoloAdd.iscrittoIl")}{" "}
                                  {item2.data_iscrizione_giorno}/
                                  {item2.data_iscrizione_mese}/
                                  {item2.data_iscrizione_anno}{" "}
                                  {t("fascicolo.fascicoloAdd.rilasciatoDa")}{" "}
                                  {item2.ente_rilascio}
                                </ExtendedTypography>
                              ))}
                            </Stack>
                          </AccordionDetails>
                        </ExtendedAccordion>
                      ))}
                    </Fragment>
                  ) : null}
                </Stack>
              </Box>
            ) : null}

            <Stack
              direction='row'
              spacing={1}
              sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
              justifyContent='right'
              alignItems='right'
            >
              <Button
                variant='outlined'
                size='small'
                onClick={handleModalClose}
              >
                {t("actions.chiudi")}
              </Button>
            </Stack>
          </Box>
        </Modal>

        {showDeleteModal && idToPerform > 0 && typeToPerform !== "" ? (
          <ModalDelete
            show={showDeleteModal}
            onClose={handleModalDeleteClose}
            onOk={handleModalDeleteOkClick}
          ></ModalDelete>
        ) : null}

        {isDataLoaded && userPermissions.canRead ? (
          <VaporPage.Section>
            <Grid container>
              <Grid item xs={12}>
                {selFascicolo.id > 0 ? (
                  <Grid item xs={12} sx={{ mt: 0 }}>
                    <ExtendedTabs
                      variant='standard'
                      size='small'
                      value={selectedTab}
                      onChange={handleChangeTab}
                      sx={{ mb: 0 }}
                    >
                      <ExtendedTab
                        value='tabAnagrafica'
                        label={t("fascicolo.form.tabAnagrafica")}
                      />
                      <ExtendedTab
                        value='tabPrestazione'
                        label={t("fascicolo.form.tabPrestazioni")}
                      />
                      <ExtendedTab
                        value='tabRischio'
                        label={t("fascicolo.form.tabRischio")}
                      />
                      <ExtendedTab
                        value='tabIndAnomalia'
                        label={t(
                          "fascicolo.fascicoloAdd.indicatoriAnomaliaLabel"
                        )}
                      />
                    </ExtendedTabs>

                    {selectedTab === "tabAnagrafica" ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <AnagraficaForm
                            fascicolo={selFascicolo}
                            reloadStatusFascicolo={handleReloadStatusFascicolo}
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    {selectedTab === "tabPrestazione" ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <PrestazioneForm
                            fascicolo={selFascicolo}
                            onRischio={handleGoToRischiPrestazione}
                            reloadStatusFascicolo={handleReloadStatusFascicolo}
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    {selectedTab === "tabRischio" ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <RischioForm
                            fascicolo={selFascicolo}
                            prestazione={selPrestazione}
                            reloadStatusFascicolo={handleReloadStatusFascicolo}
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    {selectedTab === "tabIndAnomalia" ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <IndicatoreAnomaliaForm
                            fascicolo={selFascicolo}
                            reloadStatusFascicolo={handleReloadStatusFascicolo}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </VaporPage.Section>
        ) : null}
      </VaporPage>
      {/* <VaporToolbar variant="regular" size="medium" withoutAppBar={false}
        contentRight={
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" color="secondary" size="medium" endIcon={<CancelIcon />} onClick={handleToolbarCancel}>{t("actions.annulla")}</Button>
            <Button variant="contained" size="medium" endIcon={<SaveIcon />} onClick={handleToolbarSave}>{t("actions.salva")}</Button>
          </Stack>
        }>
      </VaporToolbar> */}
    </Fragment>
  );
};
